import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import images from "../data/info/cerficateImage.json";

const Certificates = () => {
  const settings = {
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full my-8">
      <div className="my-4 text-start font-bold ">
        <h1>Certificates</h1>
      </div>
      <Slider {...settings}>
        {images.map((image) => (
          <div
            className="h-full rounded-md flex justify-center items-center"
            key={image.id}
          >
            <img
              src={require(`../data/images/Certificates/${image.url}`)}
              alt={image.alt}
              className="h-full object-cover w-full"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Certificates;
