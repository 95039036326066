// This is a React component for a footer that contains links to various pages,
// contact information, and social media icons. It also has a "Chat" button that, when
// clicked, opens a chat window.

import React, { useState } from "react";
import logo from "../data/images/LOGO612.png"; // import logo
import { Link } from "react-router-dom"; // import Link component for navigation
import "../styles/style.css"; // import global styles
import Chat from "./chat"; // import chat component
import Chatbtn from "../cards/chatbtn"; // import chat button component
import { FaArrowRight } from "react-icons/fa";
import { motion } from "framer-motion"; // Import motion from framer-motion

const Footer = () => {
  const [chat, setChat] = useState(false); // initialize chat state

  const handleChat = () => {
    setChat(!chat); // toggle chat state
  };

  return (
    <div className="footer">
      {/* <FooterUp /> */}
      <footer className=" border-shadow shadow-black shadow-xl bg-gradient-to-t from-gray-950 via-black to-blue-800 border-b-0 shadow-top rounded-3xl m-3  h-full items-center select-none">
        {/* Top footer */}
        <div className="flex lg:flex-row flex-col justify-around items-center gap-12 p-12">
          <div className="h-full flex flex-col lg:items-start justify-center p-0 gap-12">
            {/* Logo container */}
            <Link to="/" className="self-center ">
              {/* Link to home page */}
              <img
                src={logo}
                alt="Logo"
                className="w-[250px] h-[100%]  transition-all duration-300"
              />
            </Link>
            <p className="font-bold">
              GROW WITH THE MODERN ERA
              <p className="font-semibold">Learn AI Tools</p>
            </p>
          </div>

          <div className="lg:text-start text-left w-[194px]">
            {/* Privacy container */}
            <h4 className="font-bold text-2xl my-8">Resources</h4>
            {/* Title for privacy container */}
            <ul className="lg:text-start text-sm flex flex-col gap-4">
              {/* List container */}
              <li>
                <motion.div
                  whileHover={{ x: 5, color: "#3b82f6" }}
                  transition={{ duration: 0.3 }}
                  className="flex items-center"
                >
                  <Link to="/" className="flex items-center hover:text-blue-500">
                    <motion.span className="mr-2">
                      <FaArrowRight />
                    </motion.span>
                    Home
                  </Link>
                </motion.div>
              </li>
              {/* Link to privacy policy */}
              <li>
                <motion.div
                  whileHover={{ x: 5, color: "#3b82f6" }}
                  transition={{ duration: 0.3 }}
                  className="flex items-center"
                >
                  <Link to="/pricing" className=" flex items-center hover:text-blue-500">
                    <motion.span className="mr-2">
                      <FaArrowRight />
                    </motion.span>
                    Pricing
                  </Link>
                </motion.div>
              </li>
              {/* Link to terms and conditions */}
              <li>
                <motion.div
                  whileHover={{ x: 5, color: "#3b82f6" }}
                  transition={{ duration: 0.3 }}
                  className="flex items-center"
                >
                  <Link to="/about" className=" flex items-center hover:text-blue-500">
                    <motion.span className="mr-2">
                      <FaArrowRight />
                    </motion.span>
                    About Us
                  </Link>
                </motion.div>
              </li>
              <li>
                <motion.div
                  whileHover={{ x: 5, color: "#3b82f6" }}
                  transition={{ duration: 0.3 }}
                  className="flex items-center"
                >
                  <Link to="/faqs" className=" flex items-center hover:text-blue-500">
                    <motion.span className="mr-2">
                      <FaArrowRight />
                    </motion.span>
                    FAQs
                  </Link>
                </motion.div>
              </li>
              {/* Link to refund policy */}

              {/* Link to Campus Ambassador Programme Form */}
            </ul>
          </div>
          <div className="lg:text-start text-left">
            {/* Privacy container */}
            <h4 className="font-bold text-2xl my-8">Important links</h4>
            {/* Title for privacy container */}
            <ul className="lg:text-start text-sm flex flex-col gap-4">
              {/* List container */}
              <li>
                <motion.div
                  whileHover={{ x: 5, color: "#3b82f6" }}
                  transition={{ duration: 0.3 }}
                  className="flex items-center"
                >
                  <Link to="/privacy" className=" flex items-center hover:text-blue-500">
                    <motion.span className="mr-2">
                      <FaArrowRight />
                    </motion.span>
                    Privacy Policy
                  </Link>
                </motion.div>
              </li>
              {/* Link to privacy policy */}
              <li>
                <motion.div
                  whileHover={{ x: 5, color: "#3b82f6" }}
                  transition={{ duration: 0.3 }}
                  className="flex items-center"
                >
                  <Link to="/terms" className=" flex items-center hover:text-blue-500">
                    <motion.span className="mr-2">
                      <FaArrowRight />
                    </motion.span>
                    Terms and Conditions
                  </Link>
                </motion.div>
              </li>
              {/* Link to terms and conditions */}
              <li>
                <motion.div
                  whileHover={{ x: 5, color: "#3b82f6" }}
                  transition={{ duration: 0.3 }}
                  className="flex items-center"
                >
                  <Link to="/refund" className=" flex items-center hover:text-blue-500">
                    <motion.span className="mr-2">
                      <FaArrowRight />
                    </motion.span>
                    Refund Policy
                  </Link>
                </motion.div>
              </li>
              <li>
                <motion.div
                  whileHover={{ x: 5, color: "#3b82f6" }}
                  transition={{ duration: 0.3 }}
                  className="flex items-center"
                >
                  <Link to="/career" className=" flex items-center hover:text-blue-500">
                    <motion.span className="mr-2">
                      <FaArrowRight />
                    </motion.span>
                    Career
                  </Link>
                </motion.div>
              </li>
              {/* Link to refund policy */}

              <li>
                <motion.div
                  whileHover={{ x: 5, color: "#3b82f6" }}
                  transition={{ duration: 0.3 }}
                  className="flex items-center"
                >
                  <Link
                    to="https://forms.gle/mmCYJMQVqVFBVbxc6"
                    className=" flex items-center hover:text-blue-500"
                  >
                    <motion.span className="mr-2">
                      <FaArrowRight />
                    </motion.span>
                    Ambassador Program
                  </Link>
                </motion.div>
              </li>

              {/* Link to Campus Ambassador Programme Form */}
            </ul>
          </div>
          <div className="lg:text-start text-left">
            {/* Contact container */}
            <h4 className="font-bold text-2xl my-8">Contact Us</h4>
            {/* Title for contact container */}
            <div className="flex text-start flex-col gap-4">
              {/* Contact information container */}
              {/* add type of also */}

              <a href="tel:+919389677352" className="text-sm">
                +91 9389677352
              </a>
              {/* Phone number */}
              <a
                href="mailto:info@progyaneducations.in"
                className="text-sm"
                typeof="Email"
              >
                info@progyaneducations.in
              </a>
              {/* Email address */}
            </div>
          </div>
        </div>
        {/* Copyright */}
        <div className="w-full bg-white mb-4"></div>
        <div className="mt-0">
          <small className="font-semibold text- mb-2">
            Copyright &copy; {new Date().getFullYear()} PROGYAN EDUCATIONS ESTEEMED TO BE
            Pvt. Ltd. {` `}
          </small>
        </div>
        {/* No changes required */}
        <button className="fixed bottom-5 right-5 z-10" onClick={handleChat}>
          {" "}
          {chat ? "" : <Chatbtn />}{" "}
        </button>
        {chat && <Chat handleClose={handleChat} />}
      </footer>
    </div>
  );
};
export default Footer;
