import React, { useEffect } from "react";
import testimonialsData from "../data/info/testimonials.json";
import "../styles/style.css";
import FeedbackCard from "../cards/FeedbackCard";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Testimonials = () => {
  useEffect(() => {
    gsap.fromTo(
      ".first h1, .first p",
      {
        x: -400,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 1,
        delay: 0.5,
        stagger: 0.5,
        scrollTrigger: {
          trigger: ".first h1",
        },
      }
    );

    //  Add gsap animation in feedback cards..!
    const feedbackCards = document.querySelectorAll(
      ".feedback-container .card"
    );
    feedbackCards.forEach((card, index) => {
      gsap.fromTo(
        card,
        {
          y: 100,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 1,
          delay: index * 0.2,
          scrollTrigger: {
            trigger: card,
          },
        }
      );
    });
  }, []);

  return (
    <section className="container select-none flex justify-center items-center flex-col relative py-6 sm:py-16 ">
      <div className=" absolute z-[0] lg:w-[60%] h-[60%] rounded-full blue__gradient" />
      <div className="w-full flex lg:flex-row justify-between items-center flex-col md:flex-row mb-6 sm:mb-16 realtive z-[1] first">
        <h1 className="font-poppins font-semibold xs:text-[48px] text-[40px] text-white xs:leading-[76.8px] leading-[66.8px] w-full">
          What our Student <br className="hidden sm:block" /> Says about Us
        </h1>
        <div className="w-full mt-6 md:mt-0">
          <p className="font-normalself-center text-center text-dimWhite text-[18px] leading-[30.8px] md:text-left md:max-w-[450px]">
            Unlocking the potential of every learner through the synergy of AI
            and prompt engineering.
          </p>
        </div>
      </div>
      <div className="flex flex-wrap justify-center gap-20 w-full feedback-container relative z-[1]">
        {testimonialsData.slice(0, 4).map((card) => (
          <div className="card">
            <FeedbackCard key={card.id} {...card} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Testimonials;
