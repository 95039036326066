import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { loginUserUrl } from "../constant/api";

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [formErrors, setFormErrors] = useState({});
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [loading, setLoading] = useState(false); // New loading state
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: '' });
  };
  
  const handleSubmit = async(e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true on form submission
    let errors = {};
    
    // Check for empty fields
    for (let key in formData) {
      if (!formData[key]) {
        errors[key] = 'This field is required';
      }
    }
    
    setFormErrors(errors);
    if(Object.keys(errors).length !== 0) {
      setLoading(false); // Set loading to false if there are errors
      return;
    }
    
    try {
      const response = await axios.post(loginUserUrl, formData);
      localStorage.setItem('token', response.data.token);
      console.log(response);
      setLoginSuccess(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Set loading to false after the request is completed
    }
  };

  return (
    <div className="md:w-screen flex flex-col md:flex-row mt-[6rem]">
      <div className="pl-10 w-[90%] md:w-[50%]  flex flex-col items-center justify-center gap-4 md:gap-10">
        <p className="text-xl font-semibold">Welcome Back</p>
        <p className="">Login to access your account.</p>
        <Link to="/about">
          <button className="px-4 py-2 rounded rounded-xl text-black bg-white hover:text-white hover:font-semibold hover:border border hover:bg-gray-900">
            About Us
          </button>
        </Link>
      </div>
      <div className="py-10 w-[100%] md:w-[48%]">
        <form onSubmit={handleSubmit}>
          <div className="shadow border-shadow shadow-black shadow-xl bg-gradient-to-t from-gray-950 to-blue-700 border-b-0 flex flex-col gap-5 p-5 bg-opacity-50 backdrop-filter backdrop-blur-lg md:rounded-[25px] md:rounded pl-16">
            <p className="text-lg md:text-2xl font-bold">Login Here</p>
            <div className="text-left">
              <input
                type="email"
                id="email"
                autoComplete="email"
                placeholder="Your email address"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className={`w-[95%] md:w-[70%] rounded focus:bg-gray-950 font-semibold focus:text-white focus:border border-1 ${formErrors.email && "border-red-500"}`}
              />
              {formErrors.email && (
                <span className="text-red-500">{formErrors.email}</span>
              )}
            </div>
            <div className="text-left">
              <input
                type="password"
                id="password"
                autoComplete="current-password"
                placeholder="Your password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className={`w-[95%] md:w-[70%] rounded focus:bg-gray-950 font-semibold focus:text-white focus:border border-1 ${formErrors.password && "border-red-500"}`}
              />
              {formErrors.password && (
                <span className="text-red-500">{formErrors.password}</span>
              )}
            </div>
            <div className="flex flex-col md:flex-row self-center justify-between w-[95%]">
              <Link
                to="/register"
                className="text-white font-normal md:font-semibold hover:underline cursor-pointer py-4"
              >
                {" "}
                Create an account{" "}
              </Link>
              <button
                type="submit"
                className="self-right bg-gray-950 hover:bg-blue-700 rounded rounded-full text-white font-bold text-lg py-4 px-6 shadow relative"
                disabled={loading} // Disable button when loading
              >
                {loading ? "Logging in..." : "Login"}
              </button>
            </div>
            {loginSuccess && (
              <div className="text-green-500">Login successful!</div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
