import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import coursesData from "../data/info/coursesData.json";
import "../styles/style.css";
import { motion } from "framer-motion";
import ButtonWithArrow from "../cards/button";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Course = () => {
  const [visibleCourses, setVisibleCourses] = useState(3);

  const handleViewAllCourses = () => {
    setVisibleCourses(coursesData.length);
  };

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".heading, .para, .avcards, .arrowBtn",
        toggleActions: "restart none none none",
      },
    });

    tl.fromTo(
      ".heading, .para",
      {
        scrollTrigger: {
          trigger: ".heading",
        },
        x: -400,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 1,
        stagger: 0.5,
      }
    );

    tl.fromTo(
      ".avcards .avcard, .arrowBtn",
      {
        scrollTrigger: {
          trigger: ".arrowBtn",
        },
        y: 200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.5,
        stagger: 0.3,
      }
    );
  }, []);

  return (
    <section className="p-3 md:p-auto mb-10 ">
      <div className="mb-4 p-4 items-center flex flex-col">
        <span className="max-text-3xl mb-4 text-5xl md:text-5xl font-extrabold text-white heading">
          Kick Start Your Career With Our{" "}
          <span className="text-blue-500"> Top Programs</span>
        </span>
        <p className="md:w-[60%] font- self-center text-yellow-500 text-lg para">
          Progyan Educations offers a wide range of courses in the field of <span className="text-blue-500 text-2xl font-bold"><br/>Cloud Computing, Machine Learning, Data Science, Web Development </span> <br/>and more. Our courses are designed to help you build a
          strong foundation in the field of AI and Machine Learning.
        </p>
      </div>

      <div className="avcards">
        {coursesData.slice(0, visibleCourses).map((course, index) => (
          <div
            key={index}
            className="avcard avshadow border-shadow shadow-black shadow-xl bg-gradient-to-t from-gray-950 to-blue-700 border-b-0  "
          >
            <img
              src={require(`../data/images/Courses/${course.image}`)}
              alt={course.title}
              className="avcard-img"
            />
            <h4 className="avcard-title">{course.title}</h4>
            <p className="avcard-para">{course.description}</p>
            <div className="avcard-btn">
              {/* Conditional rendering of Link */}
              {course.id && (
                <Link
                  to={`/course-details/${course.id}`}
                  key={course.id}
                  className="btn btn-primary w-[40%]"
                >
                  <motion.p
                    whileHover={{ x: 5, color: "#3b82f6" }}
                    transition={{ duration: 0.3 }}
                  >
                    More
                  </motion.p>
                </Link>
              )}
              <a
                href={course.brochureLink}
                className="btn btn-primary w-[40%]"
                target="_blank"
                rel="noopener noreferrer"
              >
                <motion.p
                  whileHover={{ x: 5, color: "#3b82f6" }}
                  transition={{ duration: 0.3 }}
                >
                  Brochure
                </motion.p>
              </a>
            </div>
          </div>
        ))}
      </div>

      {visibleCourses < coursesData.length && (
        <div className="arrowBtn">
          <ButtonWithArrow title="View All Courses" onClick={handleViewAllCourses} />
        </div>
      )}
    </section>
  );
};

export default Course;
