import React, { useState, useEffect, useRef } from "react";
import { useAnimation, motion } from "framer-motion";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaArrowCircleRight } from "react-icons/fa";
import "../styles/whyus.css";
import "../styles/flipcard.css";
import Slider from "react-slick";
import reasonsData from "../data/info/reasons.json";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const WhyUs = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentText, setCurrentText] = useState("Web Development");
  const controls = useAnimation();

  const coreTexts = [
    "Web Development",
    "Machine Learning",
    "Data Science",
    "Cyber Security",
  ];

  const aiTools = {
    "Web Development": [
      "React",
      "Angular",
      "Vue",
      "Node.js",
      "Express.js",
      "Prompt Engineering",
    ],
    "Machine Learning": [
      "TensorFlow",
      "PyTorch",
      "Scikit-learn",
      "Keras",
      "Spyder",
      "Prompt Engineering",
    ],
    "Data Science": [
      "Tableau",
      "Power BI",
      "Excel",
      "SQL",
      "Python",
      "Prompt Engineering",
    ],
    "Cyber Security": [
      "Ethical Hacking",
      "Penetration Testing",
      "Incident Response",
      "Security Auditing",
      "Risk Management",
      "Prompt Engineering",
    ],
  };

  const reasons = {
    "Expert Guidance and Mentorship":
      "Our mentors are industry experts with years of experience in their respective fields. They will guide you through the course and help you understand the concepts better.",
    "Placement Assistance and Career Support":
      "We provide placement assistance to all our students and help them secure a job in their desired field. Our career support team will help you with resume building, interview preparation, and job search.",
    "Cutting-Edge AI Tools Training":
      "We provide hands-on training on the latest AI tools and technologies used in the industry. You will learn how to use tools like TensorFlow, PyTorch, Tableau, and more.",
    "Real-World Projects and Case Studies":
      "You will work on real-world projects and case studies to gain practical experience and apply the concepts you have learned in the course.",
    "Flexible Learning Options":
      "We offer flexible learning options to suit your schedule. You can choose from online, offline, and hybrid courses based on your convenience.",
    "Affordable Fees and Easy EMI Options":
      "Our courses are affordably priced, and we offer easy EMI options to help you pay for the course in installments.",
    "Global Recognition and Accreditation":
      "Our courses are globally recognized and accredited by leading organizations in the industry. You will receive a certificate upon completion of the course.",
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = (currentIndex + 1) % coreTexts.length;
      setCurrentIndex(nextIndex);
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [currentIndex, coreTexts.length]);

  useEffect(() => {
    setCurrentText(coreTexts[currentIndex]);
    controls.start({ opacity: 0 });
    controls.start({ opacity: 1 });
  }, [currentIndex, coreTexts, controls]);

  let sliderRef = useRef(null);
  const play = () => {
    sliderRef.slickPlay();
  };
  const pause = () => {
    sliderRef.slickPause();
  };

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: true,
  };

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".first, #six, .seven, .eight, .nine, .ten",
      },
    });

    tl.fromTo(
      ".first, .second",
      {
        scrollTrigger: {
          trigger: ".second",
        },
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.6,
        // delay: 0.5,
        stagger: 0.2,
      }
    );

    tl.fromTo(
      ".fifth",
      {
        x: -100,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 0.5,
        // delay: 0.5,
        stagger: 0.12,
      }
    );

    tl.fromTo(
      "#six h1, #six p",
      {
        scrollTrigger: {
          trigger: "#six",
        },
        x: -100,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 0.8,
        delay: 0.5,
        stagger: 0.2,
      }
    );
    tl.fromTo(
      ".seven h2, .seven ul li",
      {
        scrollTrigger: {
          trigger: ".seven",
        },
        x: -400,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 0.6,
        stagger: 0.3,
      }
    );
    tl.fromTo(
      ".eight span",
      {
        scrollTrigger: {
          trigger: ".eight",
        },
        x: -400,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 0.6,
        stagger: 0.3,
      }
    );
    tl.fromTo(
      ".nine div",
      {
        scrollTrigger: {
          trigger: ".nine",
        },
        x: -400,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 0.8,
        stagger: 0.3,
      }
    );
    tl.fromTo(
      ".ten button",
      {
        scrollTrigger: {
          trigger: ".ten",
        },
        x: -400,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 0.6,
        delay: 0.5,
        stagger: 0.3,
      }
    );
  }, []);

  return (
    <section className="bg-none text-gray-100 py-8 mb-4">
      <div className="container mx-auto px-2 py-16">
        <h2 className="text-5xl leading-[1.2] lg:text-6xl font-['arial'] font-semibold mt-12 text-gray-500 mb-8">
          <span className="text-yellow-500 mr-4 first">REASONS</span>
          <span className="text-white first">TO CHOOSE</span> <br></br>
          <span className="text-blue-500 second">PROGYAN EDUCATIONS</span>
        </h2>
        <div className="rounded-lg p-6 my-16 bg-gray-800 text-white ">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 ">
            {/* The Problem */}
            <div className="rounded-lg p-6 bg-gray-900 fifth">
              <h2 className="text-4xl font-semibold mb-6 text-yellow-500">The Problem</h2>
              <div className="text-start">
                <ul className="list-disc text-lg text-white ">
                  <li className="mb-2">
                    More than 36% of engineering students from IIT Bombay are not getting
                    placed.
                  </li>
                  <li className="mb-2">
                    More than 50% of students are not getting placed from other colleges.
                  </li>
                  <li className="mb-2">
                    In 2023 alone, 2.61 lakh skilled engineers were laid off.
                  </li>
                </ul>
              </div>
            </div>
            <div className="rounded-lg p-6 bg-gray-900 fifth">
              <h2 className="text-4xl font-semibold mb-6 text-yellow-500">
                Reasons to Problems
              </h2>
              <div className="text-start">
                <ul className="list-disc text-lg text-white ">
                  <li className="mb-2">Is it AI?</li>
                  <li className="mb-2">Is it lack of jobs?</li>
                  <li className="mb-2">Lack of practical knowledge.</li>
                </ul>
              </div>
            </div>

            {/* The Solution */}
            <div className="rounded-lg p-6 bg-gray-900 fifth">
              <h2 className="text-4xl font-semibold mb-6 text-yellow-500">
                The Solution
              </h2>
              <div className="text-start">
                <ul className="list-disc text-lg text-white ">
                  <li className="mb-2">We provide practical knowledge.</li>
                  <li className="mb-2">We offer training in AI tools.</li>
                  <li className="mb-2">We provide job-ready training.</li>
                  <li className="mb-2">We offer certifications.</li>
                </ul>
              </div>
            </div>

            {/* Why Us? */}
            <div className="rounded-lg p-6 bg-gray-900 fifth">
              <h2 className="text-4xl font-semibold mb-6 text-yellow-500">Why Us?</h2>
              <div className="text-start">
                <ul className="list-disc text-lg text-white ">
                  <li className="mb-2">
                    We are the first company to provide training in multiple AI tools.
                  </li>
                  <li className="mb-2">
                    We provide hands-on experience on real-world projects.
                  </li>
                  <li className="mb-2">
                    Our course structure is aligned with current industry demand.
                  </li>
                  <li className="mb-2">
                    We teach you, how to use AI, for improving your efficiency.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8" id="six">
          {/* <!-- Reason 1 --> */}
          <div className="flex flex-col justify-center">
            <h1 className="text-yellow-500 text-5xl font-bold mb-3 items-start justify-center flex ">
              Why Choose Us?
            </h1>
            <p className="text-dimWhite text-lg text-justify">
              We are dedicated to providing unparalleled expertise and support to help you
              achieve your goals. Choose us for a transformative learning experience that
              sets you on the path to success.
            </p>
          </div>
          <div
            className="slider-container shadow-[20px_35px_60px_-15px_rgba(0,0,0,0.3)] rounded-md overflow-hidden"
            id="slider"
          >
            <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
              {Object.values(reasonsData).map((reason, index) => (
                <div
                  key={index}
                  className="bg-gray-800 shadow-[20px_35px_60px_-15px_rgba(0,0,0,0.3)] rounded-lg p-6"
                >
                  <div className="flex items-center mb-4">
                    <FaLocationArrow className="text-yellow-500 mr-4" />
                    <h4 className="text-xl font-semibold">{reason.title}</h4>
                  </div>
                  <h6 className="mb-4">{reason.description}</h6>
                </div>
              ))}
            </Slider>
          </div>
        </div>

        <div className="bg-gradient-to-r from-primary to-secondary rounded-lg p-8 mt-16 seven">
          <h2 className="text-4xl font-semibold mb-10 text-tertiary">
            Prompt Engineering: <span className="text-yellow-500">The Future</span> of AI
            Tools
          </h2>
          <ul className="list-disc list-inside text-white">
            <li className="text-lg font- mb-6">
              <FaArrowCircleRight className="inline-block mr-4 text-yellow-500" />
              Prompt Engineering is a revolutionary field that seamlessly blends AI tools
              with human-like prompts, ushering in a new era of intuitive and efficient
              workflows.
            </li>
            <li className="text-lg font- mb-6">
              <FaArrowCircleRight className="inline-block mr-4 text-green-500" />
              With Prompt Engineering, you can automate mundane tasks, unleash your
              creativity to generate compelling content, and effortlessly construct
              advanced AI models.
            </li>
            <li className="text-lg font- mb-6">
              <FaArrowCircleRight className="inline-block mr-4 text-blue-500" />
              At Progyan Educations, we provide comprehensive training in cutting-edge
              Prompt Engineering tools and technologies, empowering you to become a
              proficient practitioner in this dynamic field.
            </li>
            <li className="text-lg font- mb-6">
              <FaArrowCircleRight className="inline-block mr-4 text-red-500" />
              Embark on your journey towards a brighter future by joining us today and
              unlocking the vast potential of Prompt Engineering.
            </li>
          </ul>
        </div>

        <h2 class="text-3xl lg:text-5xl  font-semibold mt-12 text-gray-500 mb-8 eight">
          <span class="text-yellow-500 mr-4">JOB-READY</span>
          <span class="text-white">TRAINING</span>
        </h2>

        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-12 p-6 md:p-8">
          <div class="relative bg-cover bg-center bg-no-repeat bg-blue-500 dark:bg-blue-800 shadow-md rounded-lg p-6 md:p-8 transition duration-300 transform hover:scale-105">
            <h4 class="text-2xl md:text-3xl font-semibold mb-4 text-white dark:text-blue-300">
              Core Domains Training
            </h4>
            <p class="text-white dark:text-zinc-300 bg-blue-700 dark:bg-blue-900 bg-opacity-80 rounded-lg p-2">
              50% of preparation done after Core Domains Training
            </p>
            <p class="text-white dark:text-zinc-300">
              Get trained in core domains and Prompt Engineering simultaneously, providing
              excellent value for your investment.
            </p>
          </div>
          <div class="relative bg-cover bg-center bg-no-repeat bg-blue-500 dark:bg-blue-800 shadow-md rounded-lg p-6 md:p-8 transition duration-300 transform hover:scale-105">
            <h4 class="text-2xl md:text-3xl font-semibold mb-4 text-white dark:text-blue-300">
              AI Tools Training <p className="text-sm"> (Advanced Prompt Engineering) </p>{" "}
            </h4>
            <p class="text-white dark:text-zinc-300 bg-blue-700 dark:bg-blue-900 bg-opacity-80 rounded-lg p-2">
              65% prepared for sitting for interview after completion of Prompt
              Engineering
            </p>
            <p class="text-white dark:text-zinc-300">
              This training is designed to provide students with a comprehensive
              understanding of AI tools and technologies used in the industry.
            </p>
          </div>

          <div class="relative bg-cover bg-center bg-no-repeat bg-blue-500 dark:bg-blue-800 shadow-md rounded-lg p-6 md:p-8 transition duration-300 transform hover:scale-105">
            <h4 class="text-2xl md:text-3xl font-semibold mb-4 text-white dark:text-blue-300">
              Real-World Projects
            </h4>
            <p class="text-white dark:text-zinc-300 bg-blue-700 dark:bg-blue-900 bg-opacity-80 rounded-lg p-2">
              80% chance of clearing interview after completion of Interview Preparation
            </p>
            <p class="text-white dark:text-zinc-300">
              Engage in two real-world projects as part of the curriculum to gain
              practical experience and apply theoretical knowledge in practical scenarios.
            </p>
          </div>

          <div class="relative bg-cover bg-center bg-no-repeat bg-blue-500 dark:bg-blue-800 shadow-md rounded-lg p-6 md:p-8 transition duration-300 transform hover:scale-105">
            <h4 class="text-2xl md:text-3xl font-semibold mb-4 text-white dark:text-blue-300">
              Certificates
            </h4>
            <p class="text-white dark:text-zinc-300 bg-blue-700 dark:bg-blue-900 bg-opacity-80 rounded-lg p-2">
              90%, all prepared done once you have certificates
            </p>
            <p class="text-white dark:text-zinc-300">
              Receive three certificates upon completion of the course to enhance your
              resume and showcase your skills.
            </p>
          </div>

          <div class="relative bg-cover bg-center bg-no-repeat bg-blue-500 dark:bg-blue-800 shadow-md rounded-lg p-6 md:p-8 transition duration-300 transform hover:scale-105">
            <h4 class="text-2xl md:text-3xl font-semibold mb-4 text-white dark:text-blue-300">
              Interview Preparation
            </h4>
            <p class="text-white dark:text-zinc-300 bg-blue-700 dark:bg-blue-900 bg-opacity-80 rounded-lg p-2">
              100%
            </p>
            <p class="text-white dark:text-zinc-300">
              After completing all the modules, give skill test and score 80% to avail
              internship and placement services, including:
            </p>
            <ul class="text-white dark:text-zinc-300 ml-4 sm:list-disc">
              <li>Resume Building</li>
              <li>Interview Preparation</li>
              <li>Job Search</li>
            </ul>
          </div>
          <div class="relative flex flex-col items-center justify-center bg-cover bg-center bg-no-repeat bg-blue-500 dark:bg-blue-800 shadow-md rounded-lg p-6 md:p-8 transition duration-300 transform hover:scale-105">
            <h4 class="text-4xl md:text-4xl font-semibold mb-4 text-white">
              Your Commitment
            </h4>
            <span className="mt-10"> Minimum price guarantee</span>

            <button className="btn btn-primary">
              <Link
                to="/pricing"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-white font-semibold md:p-4 text-sm md:text-xl"
              >
                <motion.p
                  whileHover={{ y: -2, text: "xl", color: "yellow" }}
                  transition={{ duration: 0.3 }}
                >
                  Check Now
                </motion.p>
              </Link>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
