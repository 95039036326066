import React, { useState, useEffect } from "react";

const ScrollBar = () => {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      const scrolled = window.scrollY;
      const percentage = (scrolled / (scrollHeight - clientHeight)) * 100;
      setScrollPercentage(percentage);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        top: 97,
        left: 0,
        width: `${scrollPercentage}%`,
        height: "5px",
        background: "linear-gradient(to right, red, orange)",
        borderRadius: "5px",
        zIndex: 9999, // Ensure it's above other content
      }}
    />
  );
};

export default ScrollBar;
