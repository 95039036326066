import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import contactData from "../data/info/contactData.json";
import "../styles/style.css";
import "../styles/contact.css"; // Add the CSS file for the contact page
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Contact = () => {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    textarea: "",
  });

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_forms", "template_progyan", e.target, "Bc-J207tDR-jv_Dzl")
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Your message has been sent successfully.");
          setFormState({ name: "", email: "", textarea: "" }); // Reset form fields
        },
        (error) => {
          console.log(error.text);
          toast.error("Failed to send message. Please try again later.");
        }
      );
  };

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    gsap.fromTo(
      "#contact-top h2, #contact-top p",
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.8,
        delay: 0.5,
        stagger: 0.2,
        scrollTrigger: {
          trigger: "#contact-top h2",
        },
      }
    );

    gsap.fromTo(
      "#left-side p, #left-side li, .card",
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.8,
        delay: 0.5,
        scrollTrigger: {
          trigger: "#left-side",
        },
      }
    );
  }, []);

  return (
    <section className="" id="contact">
      <ToastContainer />
      <div className="mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8 lg:py-0">
        <div className="mb-4">
          <div
            className="mb-6 mt-24 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12"
            id="contact-top"
          >
            <h2 className="font-heading mb-4 font-bold tracking-tight text-white text-3xl sm:text-5xl">
              Get in Touch
            </h2>
            <p className="mx-auto mt-4 max-w-3xl text-xl text-slate-400">
              We'd love to hear from you! Drop us a message and we'll get back to you as
              soon as possible.
            </p>
          </div>
        </div>
        <div className="flex items-stretch justify-center">
          <div className="grid md:grid-cols-2 items-center">
            <div className="h-full md:pr-6 p-0" id="left-side">
              <ul className="mb-6 text-start md:p-10 md:mb-0">
                <p className="mt-0 text-justify mb-12 ml-4 text-lg text-slate-400">
                  Feel free to reach out to us for any inquiries or if you have any
                  questions. Our team is here to assist you.
                </p>
                <li className="flex text-start justify-between">
                  <div className="ml-4 mb-4">
                    <h3 className="mb-2  text-lg font-medium leading-6  text-white">
                      Our Office Address
                    </h3>
                    <p className=" text-slate-400">
                      28/5, Progyan Educations, Rama Mandi , Jalandhar
                    </p>
                    <p className=" text-slate-400">Punjab (144023), India</p>
                  </div>
                </li>
                <li className="flex text-start justify-between">
                  <div className="ml-4 mb-4">
                    <h3 className="mb-2 text-lg font-medium leading-6  text-white">
                      Contact
                    </h3>
                    <a href="tel:+919389677352" className="text-slate-400">
                      Mobile: +91 9389677352
                    </a>{" "}
                    <br />
                    {/* Phone number */}
                    <a
                      href="mailto:info@progyaneducations.in"
                      className="text-slate-400"
                      typeof="Email"
                    >
                      Email: info@progyaneducations.in
                    </a>
                  </div>
                </li>
                <li className="flex text-start justify-between">
                  <div className="ml-4 mb-4">
                    <h3 className="mb-2  text-lg font-medium leading-6  text-white">
                      Working Hours
                    </h3>
                    <p className=" text-slate-400">
                      Tuesday - Friday: 10:00 AM - 08:00 PM
                    </p>
                    <p className=" text-slate-400">Monday: Closed</p>
                  </div>
                </li>
              </ul>
            </div>
            <div
              className="card border-shadow border-b-0 border-shadow shadow-gray-900 bg-gradient-to-t from-gray-900 to-blue-700 rounded-xl shadow-lg bg-gray-600 bg-opacity-50 backdrop-filter backdrop-blur-lg h-fit max-w-6xl p-5 md:px-12 md:pt-8 login-box"
              id="form"
            >
              <h2 className="mb-4 text-2xl font-bold">Have a query ? Let Us Know</h2>
              <form onSubmit={sendEmail} className="border-b-0 rounded p-2 form">
                <div className="mb-0">
                  <div className="mx-0 mb-1 sm:mb-4">
                    <div className="mx-0 mb-1 sm:mb-4 ">
                      <label
                        htmlFor="name"
                        className="pb-1 text-xs uppercase tracking-wider"
                      ></label>
                      <input
                        type="text"
                        id="name"
                        autoComplete="given-name"
                        placeholder="Your name"
                        className="mb-2  w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md text-gray-300 sm:mb-0 "
                        name="name"
                        value={formState.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mx-0 mb-1 sm:mb-4">
                      <label
                        htmlFor="email"
                        className="pb-1 text-xs uppercase tracking-wider"
                      ></label>
                      <input
                        type="email"
                        id="email"
                        autoComplete="email"
                        placeholder="Your email address"
                        className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4  dark:text-gray-300 sm:mb-0 "
                        name="email"
                        value={formState.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="mx-0 mb-1 sm:mb-4">
                    <label
                      htmlFor="textarea"
                      className="pb-1 text-xs uppercase tracking-wider"
                    ></label>
                    <textarea
                      id="textarea"
                      name="textarea"
                      cols="30"
                      rows="5"
                      placeholder="Write your message..."
                      className=" w-full rounded-md border border-gray-400 py-2 pl-2 pr-4  dark:text-gray-300 sm:mb-0 resize-none"
                      value={formState.textarea}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <center>
                  <button type="submit">
                    SEND
                    <span></span>
                  </button>
                </center>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
