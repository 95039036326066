import React from "react";
import { Link } from "react-router-dom";
import coursesData from "../data/info/coursesData.json";
import "../styles/style.css";
import Certificates from "../components/Certificates";
import { motion } from "framer-motion";
// motion

const Courses = () => {
  return (
    <section className="courses-section p-5 md:p-10 my-16">
      <div className="mb-8 text-center">
        <h1 className="text-4xl md:text-6xl font-bold mt-14 text-white mb-4">
          Unlock Your Potential with Progyan Education
        </h1>
        <p className="text-lg md:text-xl text-yellow-400 font-bold">
          Empowering You with AI and Prompt Engineering
        </p>
      </div>
      <div className="flex flex-wrap justify-center gap-5">
        {coursesData.map((course) => (
          <div
            key={course.id}
            className="avcard avshadow border-shadow shadow-xl bg-gradient-to-t from-gray-950 to-blue-700 p-4 lg:max-w-[30%] hover:bg-blue-800 hover:cursor-pointer transition rounded-xl"
          >
            <article className="course-content gap-5">
              <div className="course-image">
                <img
                  src={require(`../data/images/Courses/${course.image}`)}
                  alt={course.title}
                  className="w-full h-auto rounded object-cover"
                />
              </div>
              <div className="course-details p-4 font-semibold">
                <h2 className="text-xl md:text-2xl text-white">{course.title}</h2>
                <p className="text-sm text-gray-300 mb-2">
                  With AI Tools and Prompt Engineering
                </p>
                <p className="text-gray-400">{course.description}</p>
                <p className="text-gray-400">Duration: {course.duration}</p>
              </div>
              <div className="buttons-container flex items-center justify-between p-4 md:w-full gap-2 sm:p-2">
                <button className="btn btn-primary rounded-lg bg-orange-400">
                  <Link to="/pricing" rel="noopener noreferrer">
                    <motion.p
                      whileHover={{ x: 5, color: "#3b82f6" }}
                      transition={{ duration: 0.3 }}
                    >
                      Register
                    </motion.p>
                  </Link>
                </button>
                <Link
                  to={`/course-details/${course.id}`}
                  className="btn btn-primary rounded-lg bg-orange-400"
                  rel="noopener noreferrer"
                >
                  <motion.p
                    whileHover={{ x: 5, color: "#3b82f6" }}
                    transition={{ duration: 0.3 }}
                  >
                    Learn More
                  </motion.p>
                </Link>
              </div>
            </article>
          </div>
        ))}
      </div>
      <div>
        <h1></h1>
      </div>
      <Certificates />
    </section>
  );
};

export default Courses;
