import React from "react";
import CourseReq from "../data/info/requirement.json";
import { useLocation } from "react-router-dom";

const Requirements = () => {
  const location = useLocation();
  const reqId = location.pathname.split("/").pop();
  const req = CourseReq.find((req) => req.id === reqId);

  if (!req) {
    return <div>Error: Course requirements not found</div>;
  }

  if (!req.topics || req.topics.length === 0) {
    return <div>Error: Course requirements not defined</div>;
  }

  return (
    <div className="w-full h-full p-5 sm:mt-8 mb-4">
      <div className="flex items-start my-4">
        <h1 className="font-bold leading-4">Requirements</h1>
      </div>
      <div className="lg:flex mt-8 text-left">
        <ul className="lg:p-7 p-3 flex flex-col gap-4 text-xl">
          {req.topics.map((topic, index) => (
            <li className="list-disc" key={index}>
              {topic}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Requirements;
