// Header.js
"use client";
import React, { useEffect } from "react";
import "../styles/style.css";
import { TypewriterEffectSmooth } from "../ui/typeWriter.jsx";
import headerImage from "../data/images/ai.png";
import { gsap } from "gsap";
const Header = () => {
  const words = [
    {
      text: "Embrace",
    },
    {
      text: "the",
    },
    {
      text: "AI",
    },
    {
      text: "Revolution.",
    },
    {
      text: "Begin",
    },
    {
      text: "Your",
    },
    {
      text: "Journey Now.",
      className: "text-blue-500 dark:text-blue-500",
    },
  ];

  // Call the animateHeader function when the component mounts
  useEffect(() => {
    const tl = gsap.timeline();
    tl.fromTo(
      "#header h1, #header p, .images",
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.5,
        delay: 1,
        stagger: 0.2,
      }
    );
  }, []);

  return (
    <section className="">
      <div className="grid lg:flex lg:flex-row md:max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid lg:grid-cols-12 z-9">
        <div className=" md:hidden mt-20 lg:mt-0 lg:col-span-5 ">
          <img src={headerImage} alt="mockup" className="" />
        </div>
        <div
          className="mr-auto text-start place-self-center md:mt-32 lg:col-span-7"
          id="header"
        >
          <h1 className="text-blue-400 text-4xl font-bold tracking-tight mt-16 md:text-5xl mb-4">
            Progyan Educations
          </h1>
          <h1 className="text-4xl font-bold tracking-tight md:text-5xl mb-8">
            Education reimagined for the modern era.
          </h1>
          <p className="max-w-2xl lg:mb-8 font-normal text-lg">
            {/* // Emphasis on innovation and impact */}
            Harness cutting-edge AI tools and unlock limitless possibilities through
            Progyan Educations' immersive, live-streamed training and internships,
            designed for the modern world.
          </p>
          <p className="max-w-2xl lg:mb-8 text-2xl font-bold">
            {/* // Clear call to action with a futuristic tone */}
            <TypewriterEffectSmooth words={words} />
          </p>
        </div>
        <div className="hidden lg:col-span-5 md:flex md:mt-32">
          <img src={headerImage} alt="mockup" className="images" />
        </div>
      </div>
    </section>
  );
};

export default Header;
