import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";
import logo from "../data/images/LOGO612.png";
import { motion } from "framer-motion";
import ScrollBar from "../cards/scrollBarLength";
import { gsap } from "gsap";
const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const nav = document.querySelector("nav");
      nav.classList.toggle("window-scroll", window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  // GSAP Animations
  // useEffect(() => {
  //   const tl = gsap.timeline();
  //   tl.fromTo(
  //     "#nav div, #nav ul li ",
  //     {
  //       y: -100,
  //       opacity: 0,
  //     },
  //     {
  //       y: 0,
  //       opacity: 1,
  //       duration: 0.8,
  //       delay: 0.4,
  //       stagger: 0.3,
  //     }
  //   );
  // }, []);

  return (
    <motion.nav
      className="text-white fixed w-full z-50 top-0 left-0 transition-all duration-300"
      id="nav"
    >
      <div className="max-w-screen-xl h-full flex flex-wrap items-center justify-between mx-auto px-4">
        <Link to="/" className="items-center text-start gap-0 line-h-0 p-0  ">
          <img
            src={logo}
            alt="Logo"
            className="md:w-[250px] md:h-[80%] w-[180px] h-[60%] transition-all duration-300"
          />
        </Link>
        <button
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm rounded-lg lg:hidden hover:bg-gray-700"
          aria-controls="navbar-default"
          aria-expanded={menuOpen ? "true" : "false"}
          onClick={handleMenuClick}
        >
          {menuOpen ? (
            <FiX className="w-5 h-5 text-white " />
          ) : (
            <FiMenu className="w-5 h-5 text-white " />
          )}
        </button>
      </div>
      <div
        className={`absolute top-20 lg:top-5 lg:right-20 w-full lg:block lg:w-auto ${
          menuOpen ? "" : "hidden"
        }`}
        id="navbar-default"
      >
        <ul className="font-medium flex flex-col items-center p-4 mt-4 border lg:p-2 lg:px-4 rounded-lg lg:flex-row lg:space-x-8 rtl:space-x-reverse lg:mt-0 lg:border-0 bg-gray-900 lg:bg-gray-950 border-gray-700 ">
          <li>
            <Link
              to="/"
              className="block py-2 px-3 md:border-0 md:hover:text-blue-700 md:p-0 text-white"
              onClick={handleMenuClick}
            >
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className=" text-lg lg:text-xl"
              >
                Home
              </motion.button>
            </Link>
          </li>
          <li>
            <Link
              to="/courses"
              className="block py-2 px-3 md:border-0 md:hover:text-blue-700 md:p-0 text-white"
              onClick={handleMenuClick}
            >
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className=" text-lg lg:text-xl"
              >
                Courses
              </motion.button>
            </Link>
          </li>
          <li>
            <Link
              to="/pricing"
              className="block py-2 px-3 md:border-0 md:hover:text-blue-700 md:p-0 text-white"
              onClick={handleMenuClick}
            >
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className=" text-lg lg:text-xl"
              >
                Pricing
              </motion.button>
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className="block py-2 px-3 md:border-0 md:hover:text-blue-700 md:p-0 text-white"
              onClick={handleMenuClick}
            >
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className=" text-lg lg:text-xl"
              >
                Contact
              </motion.button>
            </Link>
          </li>
          <li>
            <Link
              to="/register"
              className="block px-3 py-2 border border-transparent text-white transition-all duration-300 rounded font-bold hover:bg-gray-100 md:hover:text-black "
              onClick={handleMenuClick}
            >
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className=" text-lg lg:text-xl"
              >
                Join Now
              </motion.button>
            </Link>
          </li>
        </ul>
      </div>
      <ScrollBar />
    </motion.nav>
  );
};

export default Navbar;
