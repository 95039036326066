import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import "./PointerTrail.css"; // Import the PointerTrail.css file
import Home from "../src/home"; // Assuming the home page is in 'pages/home.js'
import Contact from "./pages/contact";
import About from "./pages/about";
import Courses from "./pages/courses";
import Footer from "./components/footer";
import Testimonials from "./page/testimonial"; // Corrected 'page' to 'pages'
import FAQs from "./pages/faqs";
import TermsAndConditions from "./page/termsandconditions";
import PrivacyPolicy from "./page/privacypolicy";
import RefundPolicy from "./page/refundpolicy";
import Navbar from "./components/Navbar";
import RegistrationForm from "./page/registrationForm";
import CourseDetails from "./page/courseDetails";
import FeedbackForm from "./page/feedbackForm";
import Login from "./page/login";
import WhyUs from "./page/whyUs";
import CareerPage from "./pages/career/careers";
import FeesStructure from "./page/feeStructure";
import ScrollToTop from "./ui/ScrollToTop"; // Import the ScrollToTop component
function App() {
  const [showControlCircle, setShowControlCircle] = useState(false);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Control") {
        setShowControlCircle(true);
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === "Control") {
        setShowControlCircle(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <div className="App">
      {showControlCircle && <div className="control-circle"></div>}
      <div className="pointer-trail"></div>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<WhyUs />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/testimonial" element={<Testimonials />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/pricing" element={<FeesStructure />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/refund" element={<RefundPolicy />} />
        <Route path="/register" element={<RegistrationForm />} />
        <Route
          path="/course-details/:courseTitle"
          element={<CourseDetails />}
        />
        <Route path="/feedbackForm" element={<FeedbackForm />} />
        <Route path="/login" element={<Login />} />
        <Route path="/whyUs" element={<WhyUs />} />
        <Route path="/career" element={<CareerPage />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
