import React from "react";
import { useLocation } from "react-router-dom";
import coursesData from "../data/info/coursesData.json";
import aitools from "../data/info/aiToolsData.json";
import { Link } from "react-router-dom";
import CourseDiscription from "./courseDiscription";
import Requirements from "./requirements";
import HighlightedReview from "./highlightedReview";
import BlogPost from "../components/blogPost";
import Certificates from "../components/Certificates";
import { motion } from "framer-motion";

const CourseDetails = () => {
  const location = useLocation();
  const courseId = location.pathname.split("/").pop();
  const course = coursesData.find((course) => course.id === parseInt(courseId));
  const aiTool = aitools.find((aiTool) => aiTool.id === parseInt(courseId));

  if (!course) {
    return <div>No course details available</div>;
  }

  return (
    <div className="mt-[6rem] ">
      <div className="container mx-auto p-0 lg:px-4 py-4">
        <div className="flex flex-col border md:border-none lg:flex-row  bg-g0 p-5 rounded-lg">
          <img
            src={require(`../data/images/Courses/${course.image}`)}
            alt="Course"
            className="md:h-[400px] w-auto rounded-xl hover:scale-[1.01] duration-300 transition cursor-pointer"
          />
          <div className="relative my-5 lg:my-0 lg:mx-10 text-start gap-10 flex flex-col px-4 md:py-5 md:px-3">
            <h1 className="">{course.title}</h1>
            <p>{course.description}</p>
            <div className="buttons-container flex flex-col sm:flex-row items-center justify-between gap-4 sm:gap-2 sm:p-2">
              <Link to={`${course.brochureLink}`}>
                <button className="btn btn-primary rounded-lg bg-orange-400">
                  <motion.p
                    whileHover={{ x: 5, color: "#3b82f6" }}
                    transition={{ duration: 0.3 }}
                  >
                    Brochure
                  </motion.p>
                </button>
              </Link>
              <Link to="/pricing">
                <button className="btn btn-primary rounded-lg bg-orange-400">
                  <motion.p
                    whileHover={{ x: 5, color: "#3b82f6" }}
                    transition={{ duration: 0.3 }}
                  >
                    Enroll Now
                  </motion.p>
                </button>
              </Link>
            </div>
          </div>
        </div>
        <CourseDiscription />
        <div>
          <div className="text-left my-5 font-bold">
            <h2 className="mt-10 lg:mt-6">With AI tools included</h2>
          </div>
          <BlogPost />
          <Requirements />
        </div>
        <HighlightedReview />
        <Certificates />
      </div>
    </div>
  );
};

export default CourseDetails;
