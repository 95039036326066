import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/style.css";
import { FaCheck, FaPaperPlane, FaPlane, FaRupeeSign } from "react-icons/fa";
import { FaShuttleSpace } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const FeesStructure = () => {
  useEffect(() => {
    gsap.fromTo(
      "#top h1",
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.5,
        delay: 0.5,
        stagger: 0.2,
        scrollTrigger: {
          trigger: "#top h1",
        },
      }
    );

    gsap.fromTo(
      "#bottom div",
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.8,
        delay: 0.5,
        scrollTrigger: {
          trigger: "#bottom div",
        },
      }
    );
  }, []);

  return (
    <div className="w-full xl:overflow-hidden items-center select-none flex flex-col fees">
      <div className="w-full h-[10%] flex items-center justify-center p-12 mb-4" id="top">
        <h1 className="text-6xl text-white font-semibold space-x-4 mt-24">
          Fees Structure
        </h1>
      </div>
      <div
        className="flex w-full h-[90%] flex-col lg:flex-row items-center justify-evenly gap-3 px-2 mb-5"
        id="bottom"
      >
        <div className=" rounded-lg bg-[#1d1e20] border-2 md:w-[400px] p-4 sm:p-8 lg:my-4 my-8 h-full ">
          <div className="flex flex-col gap-4 items-center justify-center w-full p-2 my-4">
            <div className="w-full flex flex-col items-center justify-center">
              <span className="p-4 rounded-full shadow-2xl text-sky-500">
                <FaPaperPlane size={50} />
              </span>
              <h2 className="text-2xl text-white font-bold uppercase mt-4 mb-2">
                Blended
              </h2>
            </div>
            <div className="w-full flex gap-2 items-center justify-center">
              <div className="line-through text-white font-bold flex items-center opacity-35 justify-center">
                <FaRupeeSign size={15} />
                <h1 className="text-xl">5000.00</h1>
              </div>
              <p className="p-2 uppercase font-semibold rounded-xl text-lg bg-white text-indigo-600">
                Save 40%
              </p>
            </div>
            <div className="w-full flex justify-center items-baseline">
              <span className="text-xl text-white font-bold">
                <FaRupeeSign />
              </span>
              <h1 className="text-6xl text-white font-bold ">2999.00</h1>
            </div>
          </div>
          <div className="w-full">
            <button className="px-16 rounded-lg py-4 text-center cursor-pointer shadow-lg bg-indigo-600 hover:bg-indigo-800">
              <Link
                to={"https://rzp.io/l/progyan-registration-blended"}
                className="text-xl text-white font-semibold"
              >
                Enroll now
              </Link>
            </button>
          </div>
          <div className="w-full flex justify-center items-baseline my-4">
            <span className="text-sm text-slate-400 ">
              <FaRupeeSign size={10} />
            </span>
            <div className="flex mr-1">
              <h1 className="text-sm text-slate-400  ">1000.00</h1>
              <span className="text-sm text-slate-400"> /mo </span>
            </div>
            <p className="text-sm text-slate-400"> if you enroll now..!</p>
          </div>
          <div className="w-[95%] items-center mx-auto mt-6 h-[2px] bg-slate-500"></div>
          <div className="w-full">
            <ul className="w-full text-white">
              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">Recorded Lectures</p>
              </li>
              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">Real Time Projects</p>
              </li>
              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">Weekly Assignments</p>
              </li>
              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">Learn 5+ AI tools</p>
              </li>
              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">10+ Hrs of Live Sessions</p>
              </li>
              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="md:text-md tracking-tight">Doubt Clearing</p>
              </li>
              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">3+ Certifications</p>
              </li>
              <li className="flex items-center my-4 gap-2 ">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">Resume & LinkedIn Builder</p>
              </li>
              <li className="flex items-center my-4 gap-2 ">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">Placement Guidance</p>
              </li>
              <li className="flex items-center my-4 gap-2 opacity-30">
                <ImCross size={20} className="text-red-400" />
                <p className="text-md tracking-tight">Mentor Support</p>
              </li>
              <li className="flex items-center my-4 gap-2 opacity-30">
                <ImCross size={20} className="text-red-400" />
                <p className="text-md tracking-tight">Guaranteed Internship</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="relative rounded-lg bg-[#1d1e20] border-indigo-600 border-2 md:w-[400px] p-4 sm:p-8 lg:my-4 my-12 h-full ">
          <div className="absolute bg-rose-500 font-bold uppercase -top-7 sm:-top-6 right-[82px] sm:right-20 text-lg p-2 px-4 sm:text-xl sm:p-4 sm:px-12 rounded-lg">
            Most Popular
          </div>
          <div className="flex flex-col gap-4 items-center justify-center w-full p-2 my-4">
            <div className="w-full flex flex-col items-center justify-center">
              <span className="p-4 rounded-full shadow-2xl text-indigo-500">
                <FaPlane size={50} />
              </span>
              <h2 className="text-2xl text-white font-bold uppercase mt-4 mb-2">
                ADVANCE GUIDANCE
              </h2>
            </div>
            <div className="w-full flex gap-2 items-center justify-center">
              <div className="line-through text-white font-bold flex items-center opacity-35 justify-center">
                <FaRupeeSign size={15} />
                <h1 className="text-xl"> 7000.00</h1>
              </div>
              <p className="p-2 uppercase font-semibold rounded-xl text-lg bg-white text-rose-500">
                Save 29%
              </p>
            </div>
            <div className="w-full flex justify-center items-baseline">
              <span className="text-xl text-white font-bold">
                <FaRupeeSign />
              </span>
              <h1 className="text-6xl text-white font-bold ">4999.00</h1>
            </div>
          </div>
          <div className="w-full">
            <button className="px-16 rounded-lg py-4 text-center cursor-pointer shadow-lg bg-rose-500 hover:bg-rose-700">
              <Link
                to={"https://pages.razorpay.com/pl_O2Z9UBGnH91o8N/view"}
                className="text-xl text-white font-semibold"
              >
                Enroll now
              </Link>
            </button>
          </div>
          <div className="w-full flex justify-center items-baseline my-4">
            <span className="text-sm text-slate-400 ">
              <FaRupeeSign size={10} />
            </span>
            <div className="flex mr-1">
              <h1 className="text-sm text-slate-400  ">1667.00</h1>
              <span className="text-sm text-slate-400"> /mo </span>
            </div>
            <p className="text-sm text-slate-400"> if you enroll now..!</p>
          </div>
          <div className="w-[95%] items-center mx-auto mt-6 h-[2px] bg-slate-500"></div>
          <div className="w-full">
            <ul className="w-full text-white">
              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">Recorded Lectures</p>
              </li>
              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">Real Time Projects</p>
              </li>
              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">Weekly Assignments</p>
              </li>
              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">Learn 5+ AI tools</p>
              </li>
              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">50+ Hrs of Live Sessions</p>
              </li>

              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="md:text-md tracking-tight">One on One Doubt Clearing</p>
              </li>
              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">5+ Certifications</p>
              </li>
              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">Mentor Support</p>
              </li>
              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">Resume & LinkedIn Builder</p>
              </li>
              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">Placement Guidance</p>
              </li>
              <li className="flex items-center my-4 gap-2 opacity-30">
                <ImCross size={20} className="text-red-400" />
                <p className="text-md tracking-tight">Guaranteed Internship</p>
              </li>
            </ul>
          </div>
        </div>
        <div className=" rounded-lg bg-[#1d1e20] border-2 md:w-[400px] p-4 sm:p-8 lg:my-4 my-12 h-full ">
          <div className="flex flex-col gap-4 items-center justify-center w-full p-2 my-4">
            <div className="w-full flex flex-col items-center justify-center">
              <span className="p-4 rounded-full shadow-2xl ">
                <FaShuttleSpace size={50} className="-rotate-90 text-rose-500 " />
              </span>
              <h2 className="text-2xl text-white font-bold uppercase mt-4 mb-2">
                EXPERT GUIDANCE
              </h2>
            </div>
            <div className="w-full flex gap-2 items-center justify-center">
              <div className="line-through text-white font-bold flex items-center opacity-35 justify-center">
                <FaRupeeSign size={15} />
                <h1 className="text-xl">10000.00</h1>
              </div>
              <p className="p-2 uppercase font-semibold rounded-xl text-lg bg-white text-indigo-600">
                Save 20%
              </p>
            </div>
            <div className="w-full flex justify-center items-baseline">
              <span className="text-xl text-white font-bold">
                <FaRupeeSign />
              </span>
              <h1 className="text-6xl text-white font-bold ">7999.00</h1>
            </div>
          </div>
          <div className="w-full">
            <button className="px-16 rounded-lg py-4 text-center cursor-pointer shadow-lg bg-indigo-600 hover:bg-indigo-800">
              <Link
                to={"https://rzp.io/l/progyan-registration-advance"}
                className="text-xl text-white font-semibold"
              >
                Enroll now
              </Link>
            </button>
          </div>
          <div className="w-full flex justify-center items-baseline my-4">
            <span className="text-sm text-slate-400 ">
              <FaRupeeSign size={10} />
            </span>
            <div className="flex mr-1">
              <h1 className="text-sm text-slate-400  ">2667.00</h1>
              <span className="text-sm text-slate-400"> /mo </span>
            </div>
            <p className="text-sm text-slate-400"> if you enroll now..!</p>
          </div>
          <div className="w-[95%] items-center mx-auto mt-6 h-[2px] bg-slate-500"></div>
          <div className="w-full">
            <ul className="w-full text-white">
              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">Recorded Lectures</p>
              </li>
              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">Real Time Projects</p>
              </li>
              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">Weekly Assignments</p>
              </li>
              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">Learn 10+ AI tools</p>
              </li>
              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">100+ Hrs of Live Sessions</p>
              </li>
              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="md:text-md tracking-tight">One on One Doubt Clearing</p>
              </li>
              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">5+ Certifications</p>
              </li>
              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">Mentor Support</p>
              </li>
              <li className="flex items-center my-4 gap-2 ">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">Resume & LinkedIn Builder</p>
              </li>
              <li className="flex items-center my-4 gap-2 ">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">Placement Guidance</p>
              </li>
              <li className="flex items-center my-4 gap-2">
                <FaCheck size={20} className="text-green-400" />
                <p className="text-md tracking-tight">Guaranteed Internship</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeesStructure;
