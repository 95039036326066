import React from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import { MdClose } from "react-icons/md";
import Chatbtn from "../cards/chatbtn";

const theme = {
  background: "#f5f8fb",
  fontFamily: "Arial",
  headerBgColor: "#424890",
  headerFontColor: "#fff",
  headerFontSize: "15px",
  botBubbleColor: "#424890",
  botFontColor: "#fff",
  userBubbleColor: "#fff",
  userFontColor: "#4a4a4a",
};

const Chat = ({ handleClose }) => {
  return (
    <div className="fixed bottom-1 right-1 z-10">
      <ThemeProvider theme={theme}>
        <ChatBot
          handleEnd={() => handleClose()} // Close chat on end
          steps={[
            {
              id: "1",
              message: "Hello, How can I help you?",
              trigger: "options",
            },
            {
              id: "courses",
              message:
                "We offer various courses, you can check them out on our courses page",
              trigger: "options",
            },
            {
              id: "contact",
              message: "You can email us at progyaneducations@gmail.com",
              trigger: "options",
            },
            {
              id: "about",
              message:
                "We are a company that offers various courses, you can check them out on our about page",
              trigger: "options",
            },
            {
              id: "options",
              options: [
                { value: "courses", label: "Courses", trigger: "courses" },
                { value: "contact", label: "Contact", trigger: "contact" },
                { value: "about", label: "About", trigger: "about" },
              ],
            },
          ]}
        />
      </ThemeProvider>
      <button className="left-0 p-3 mt-0 w-full text-xl" onClick={handleClose}>
        <MdClose />
      </button>
    </div>
  );
};

export default Chat;