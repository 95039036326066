import React from "react";
import quotes from "../data/images/quotes.svg";
// const avatar = 
// import Avatar from '@mui/material/Avatar';
import Avatar from 'react-avatar';

const FeedbackCard = ({ message, name, role, avatar }) => {
  return (
    <div className="flex justify-between border md:border-0 flex-col px-10 py-12 rounded-[20px] lg:max-w-[370px] mr-0 my-5 sm:mr-5 md:mr-8 feedback-card">
      <img
        src={quotes}
        alt="quotes"
        className="w-[42px] h-[42px] object-contain"
      />
      <p className="font-normal text-[18px] leading-[32px] text-white my-10">
        {message}
      </p>
      <div className="flex w-full flex-row sm:justify-center justify-normal">
        <img
          src='https://cdn-ackhb.nitrocdn.com/YTibXMIwXCUkXtfHFnkSuEHUqRRKKBBW/assets/images/optimized/rev-f9b39bf/kindlepreneur.com/wp-content/uploads/2022/12/book-logo-ai.png'
          alt={name}
          className="w-[48px] h-[48px] rounded-full"
        />
        <div className="flex flex-col ml-4">
          <h4 className="font-semibold text-[20px] leading-[32px] text-white">
            {name}
          </h4>
          <p className="font-normal text-[16px] leading-[24px] text-dimWhite">
            {role}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FeedbackCard;
