import React, { useState } from "react";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Loader } from "rsuite";
import axios from "axios";
import { registerUserUrl } from "../constant/api";

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [loading, setLoading] = useState(false); // New loading state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true on form submission
    let errors = {};

    // Check for empty fields
    for (let key in formData) {
      if (!formData[key]) {
        errors[key] = "This field is required";
      }
    }

    // Check if password matches confirmPassword
    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }

    // Check for valid email format
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      errors.email = "Invalid email format";
    }

    setFormErrors(errors);
    if (Object.keys(errors).length !== 0) {
      setLoading(false); // Set loading to false if there are errors
      return;
    }

    const body = {
      name: formData.name,
      email: formData.email,
      password: formData.password,
      mobile: formData.phone,
    };

    try {
      const response = await axios.post(registerUserUrl, body);
      localStorage.setItem("token", response.data.token);
      console.log(response);
      setRegistrationSuccess(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Set loading to false after the request is completed
    }
  };

  return (
    <div className="md:w-screen flex flex-col md:flex-row mt-[6rem]">
      <div className="pl-10 w-[90%] md:w-[50%]  flex flex-col items-center justify-center gap-4 md:gap-10">
        <div className="rotate-25 mt-16 lg:mt-0">
          <FaLocationArrow size={64} rotate={90} color="" />
        </div>
        <p className="text-xl font-semibold">Join Us</p>
        <p className="">
          Once you register, <br /> you will be allowed to access our courses.
        </p>
        <Link to="/about">
          <button className="px-4 py-2 rounded rounded-xl text-black bg-white hover:text-white hover:font-semibold hover:border border hover:bg-gray-900">
            About Us
          </button>
        </Link>
      </div>
      <div className="py-10 w-[100%] md:w-[48%] ">
        <form onSubmit={handleSubmit}>
          <div className="shadow flex flex-col gap-5 border-shadow shadow-black shadow-xl bg-gradient-to-t from-gray-950 to-blue-700 border-b-0 p-5 bg-opacity-50 backdrop-filter backdrop-blur-lg md:rounded-[25px] md:rounded pl-16">
            <p className="text-lg md:text-2xl font-bold">Register Here</p>
            <div className="text-left">
              <input
                type="text"
                id="name"
                autoComplete="given-name"
                placeholder="Your name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className={`w-[95%] md:w-[70%] rounded focus:bg-gray-950 font-semibold focus:text-white focus:border border-1 ${formErrors.name &&
                  "border-red-500"}`}
              />
              {formErrors.name && (
                <span className="text-red-500 pl-1">{formErrors.name}</span>
              )}
            </div>
            {/* Other form fields */}
            <div className="text-left">
              <input
                type="email"
                id="email"
                autoComplete="email"
                placeholder="Your email address"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className={`w-[95%] md:w-[70%] rounded focus:bg-gray-950 font-semibold focus:text-white focus:border border-1 ${formErrors.email &&
                  "border-red-500"}`}
              />
              {formErrors.email && (
                <span className="text-red-500 pl-1">{formErrors.email}</span>
              )}
            </div>
            <div className="text-left">
              <input
                type="password"
                id="password"
                autoComplete="new-password"
                placeholder="Your password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className={`w-[95%] md:w-[70%] rounded focus:bg-gray-950 font-semibold focus:text-white focus:border border-1 ${formErrors.password &&
                  "border-red-500"}`}
              />
              {formErrors.password && (
                <span className="text-red-500 pl-1">{formErrors.password}</span>
              )}
            </div>
            <div className="text-left">
              <input
                type="password"
                id="confirmPassword"
                autoComplete="new-password"
                placeholder="Confirm your password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                className={`w-[95%] md:w-[70%] rounded focus:bg-gray-950 font-semibold focus:text-white focus:border border-1 ${formErrors.confirmPassword &&
                  "border-red-500"}`}
              />
              {formErrors.confirmPassword && (
                <span className="text-red-500 pl-1">
                  {formErrors.confirmPassword}
                </span>
              )}
            </div>
            <div className="text-left">
              <input
                type="tel"
                id="phone"
                autoComplete="tel"
                placeholder="Your phone number"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className={`w-[95%] md:w-[70%] rounded focus:bg-gray-950 font-semibold focus:text-white focus:border border-1 ${formErrors.phone &&
                  "border-red-500"}`}
              />
              {formErrors.phone && (
                <span className="text-red-500 pl-1">{formErrors.phone}</span>
              )}
            </div>
            <div className="flex flex-col md:flex-row self-center justify-between w-[95%]">
              <Link
                to="/login"
                className="text-white font-normal md:font-semibold hover:underline cursor-pointer py-4"
              >
                {" "}
                Already registered ?{" "}
              </Link>
              <button
                type="none"
                className="self-right bg-gray-950 hover:bg-blue-700 rounded rounded-full text-white font-bold text-lg py-4 px-6 shadow relative"
                disabled={loading} // Disable button when loading
              >
                {loading ? "Loading..." : "Register"}
              </button>
            </div>
            {registrationSuccess && (
              <div className="text-green-500">
                You have successfully registered!
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegistrationForm;
