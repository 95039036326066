import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FaYoutube, FaLinkedin, FaDiscord, FaInstagram } from "react-icons/fa";
import { motion } from "framer-motion"; // Import motion from framer-motion
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const FooterUp = () => {
  useEffect(() => {
    gsap.fromTo(
      "#top-part h1, #top-part p, #bottom-part ul",
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.8,
        delay: 0.5,
        stagger: 0.2,
        scrollTrigger: {
          trigger: "#top-part h1",
        },
      }
    );
  }, []);

  return (
    <div className="flex flex-col relative items-center max-gap-5 p-10 max-h-screen mt-10">
      <div className="heading lg:h-[30%] mb-2 " id="top-part">
        <h1 className="font-bold mb-10 text-3xl lg:text-6xl">
          Welcome to Our
          <span className="text-blue-600 mx-3">Artificial Intelligence</span>
          World!
        </h1>
        <p className="gap-3 text-xl flex flex-col">
          We are here to help you in learning the updated technologies and make you a
          professional in the field of coding.
          <br></br>
          <span className="text-yellow-500 font-bold">
            Artificial Intelligence is the new fuel of the world and we are here to make
            you the best in the field.
          </span>
        </p>
      </div>
      <div className="links lg:h-[30%] my-10" id="bottom-part">
        <ul className="text-2xl flex flex-row gap-2 md:gap-20 justify-center">
          <Link
            className="flex items-center flex-col"
            to={"https://www.instagram.com/progyaneducations"}
          >
            <motion.li className="p-6 mb-2 hover:border-1 border-1 cursor-pointer rounded-full hover:bg-white hover:text-red-400 transition duration-300 items-center flex">
              <FaInstagram size={40} />
            </motion.li>
            <p className="text-sm">Instagram</p>
          </Link>
          <Link
            className="flex items-center flex-col"
            to="https://www.linkedin.com/in/progyaneducations-/"
          >
            <motion.li className="p-6 mb-2 hover:border-1 border-1 cursor-pointer rounded-full hover:bg-white hover:text-blue-500 transition duration-300 items-center flex">
              <FaLinkedin size={40} />
            </motion.li>
            <p className="text-sm">Linkedin</p>
          </Link>
          <li className="flex items-center flex-col">
            <motion.li
              to="https://www.instagram.com/progyaneducations"
              className="p-6 mb-2 hover:border-1 border-1 cursor-pointer rounded-full hover:bg-white hover:text-violet-500 transition duration-300 items-center flex"
            >
              <FaDiscord size={40} />
            </motion.li>
            <p className="text-sm">Discord</p>
          </li>
          {/* <li className="flex items-center flex-col">
            <motion.li
              to="https://www.instagram.com/progyaneducations"
              className="p-6 mb-2 hover:border-1 border-1 cursor-pointer rounded-full hover:bg-white hover:text-red-500 transition duration-300 items-center flex"
              id="list"
            >
              <FaYoutube size={40} />
            </motion.li>
            <p className="text-sm">Youtube</p>
          </li> */}
        </ul>
      </div>
      {/* <div className=' max-mt-10 transition p-16 duration-300 mx-auto self-center lg:w-[60%] h-[30%] flex flex-col max-gap-5 rounded rounded-3xl items-center text-4xl justify-center text-white bg-gradient-to-b from-blue-950 to-blue-900'>
                <h1 className='font-bold mb-4 text-2xl lg:text-3xl'>

                    Want to know more, why to learn AI tools (Prompt Engineering).
                </h1>
                <button>
                    <Link to='/about' className='border text-lg lg:text-2xl border-2 p-2 md:p-4 rounded rounded-full transition duration-300 hover:bg-white hover:text-black font-bold'>
                        Learn More
                    </Link>
                </button>
            </div> */}
    </div>
  );
};

export default FooterUp;
