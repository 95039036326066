import React from "react";
import aitools from "../data/info/aiToolsData.json";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/blogpost.css";

const BlogPost = () => {
  const settings = {
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full mx-auto">
      <Slider {...settings}>
        {aitools.map((data) => (
          <div
            key={data.id}
            className="bg-[#111827] h-[500px] text-white rounded-xl"
          >
            <div className="h-52 rounded-xl flex justify-center items-center">
              <img
                src={data.image}
                alt={data.name}
                className="h-full rounded-t-xl w-full object-cover"
              />
            </div>
            <div className="flex flex-col justify-center items-center gap-4 p-4">
              <h1 className="font-semibold text-xl">{data.name}</h1>
              <div className="line-clamp-5">
                <p>{data.description}</p>
              </div>
              <a
                href={data.website}
                target="_blank"
                rel="noreferrer"
                className="readMoreBtn flex justify-center items-center font-semibold"
              >
                Read More
              </a>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BlogPost;
