import React from "react";
import "../styles/privacyPolicy.css";

function RefundPolicy() {
  const handleSelectChange = (e) => {
    const selectedSectionId = e.target.value.substring(1); // Get the section id without '#'
    const selectedSection = document.getElementById(selectedSectionId);
    if (selectedSection) {
      selectedSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  function handleSelectedChange(sectionId) {
    const element = document.querySelector(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
  return (
    <div className="content text-start">
      <div className="py-20 pt-44 md:py-32 md:pt-44">
        <div className="container max-w-6xl">
          <div className="hero text-center mb-12 md:mb-32">
            <h1 className="u-h1 font-bold mb-4">Refund Policy</h1>
            <p className="font-bold">Last updated: March 09, 2024</p>
          </div>
          <div className="md:flex justify-between items-start">
            <div className="md:w-4/12 sidebar sticky ">
              <div className="md:hidden mb-12">
                <select onChange={handleSelectChange}>
                  <option value="#returns">Returns</option>
                  <option value="#return-process">Return Process</option>
                  <option value="#refunds">Refunds</option>
                  <option value="#exceptions">Exceptions</option>
                  <option value="#additional-terms">Additional Terms</option>
                  <option value="#questions">Questions</option>
                </select>
              </div>
              <div className="rounded-lg bg-blue-gradient p-8 hidden md:block">
                <h4 className="text-xl text-white font-bold mb-4">Contents</h4>
                <ul className="list-none">
                  <li className="block hover:opacity-75 transition-opacity duration-200 ease-in-out font-sm mb-2">
                    <span className="list-triangle list-triangle--white mr-2"></span>
                    <a
                      onClick={() => handleSelectedChange("#returns")}
                      className="font-semibold text-white capitalize cursor-pointer"
                    >
                      Returns
                    </a>
                  </li>
                  <li className="block hover:opacity-75 transition-opacity duration-200 ease-in-out font-sm mb-2">
                    <span className="list-triangle list-triangle--white mr-2"></span>
                    <a
                      onClick={() => handleSelectedChange("#return-process")}
                      className="font-semibold text-white capitalize cursor-pointer"
                    >
                      Return Process
                    </a>
                  </li>
                  <li className="block hover:opacity-75 transition-opacity duration-200 ease-in-out font-sm mb-2">
                    <span className="list-triangle list-triangle--white mr-2"></span>
                    <a
                      onClick={() => handleSelectedChange("#refunds")}
                      className="font-semibold text-white capitalize cursor-pointer"
                    >
                      Refunds
                    </a>
                  </li>
                  <li className="block hover:opacity-75 transition-opacity duration-200 ease-in-out font-sm mb-2">
                    <span className="list-triangle list-triangle--white mr-2"></span>
                    <a
                      onClick={() => handleSelectedChange("#exceptions")}
                      className="font-semibold text-white capitalize cursor-pointer"
                    >
                      Exceptions
                    </a>
                  </li>
                  <li className="block hover:opacity-75 transition-opacity duration-200 ease-in-out font-sm mb-2">
                    <span className="list-triangle list-triangle--white mr-2"></span>
                    <a
                      onClick={() => handleSelectedChange("#additional-terms")}
                      className="font-semibold text-white capitalize cursor-pointer"
                    >
                      Additional Terms
                    </a>
                  </li>
                  <li className="block hover:opacity-75 transition-opacity duration-200 ease-in-out font-sm">
                    <span className="list-triangle list-triangle--white mr-2"></span>
                    <a
                      onClick={() => handleSelectedChange("#questions")}
                      className="font-semibold text-white capitalize cursor-pointer"
                    >
                      Questions
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:w-7/12">
              <div
                id="returns"
                className="mb-6 md:mb-12"
                tabIndex="-1"
                style={{ outline: "none" }}
              >
                <h3 className="u-h3 font-bold">Returns</h3>
                <div className="mt-4 md:mt-6 u-p1 post-content">
                  <p>
                    All requests for returns must be made within seven (7) days
                    of the enrollment date. Given the nature of our courses,
                    eligibility for returns is contingent on the following
                    criteria:
                  </p>
                  <ol>
                    <li>
                      For Live Courses: The request for return must be made
                      within seven (7) days of the enrollment date, and the
                      course must not have been attended beyond the first
                      session.
                    </li>
                    <li>
                      For Recording Sessions: The request for return must be
                      made within seven (7) days of the enrollment date, and
                      access to the recording must not have been utilized.
                    </li>
                    <li>
                      All accompanying materials, such as study guides or
                      additional resources, must be returned in new and unused
                      condition.
                    </li>
                  </ol>
                </div>
              </div>
              <div
                id="return-process"
                className="mb-6 md:mb-12"
                tabIndex="-1"
                style={{ outline: "none" }}
              >
                <h3 className="u-h3 font-bold">Return Process</h3>
                <div className="mt-4 md:mt-6 u-p1 post-content">
                  <p>
                    To initiate a return, please contact our support team at the
                    following address:
                  </p>
                  <p>
                    Progyan Educations Attn: Returns Progyan Educations, Rama
                    Mandi, Jalandhar Punjab (144023), India Jalandhar, Punjab
                    144023 India
                  </p>
                  <p>
                    Upon approval, return shipping charges will be covered or
                    reimbursed by us.
                  </p>
                </div>
              </div>
              <div
                id="refunds"
                className="mb-6 md:mb-12"
                tabIndex="-1"
                style={{ outline: "none" }}
              >
                <h3 className="u-h3 font-bold">Refunds</h3>
                <div className="mt-4 md:mt-6 u-p1 post-content">
                  <p>
                    Once we receive your return and verify eligibility based on
                    the outlined criteria, we will process your refund. Please
                    allow up to fourteen (14) days from the receipt of your
                    return for the refund to be processed. Refunds may take 1-2
                    billing cycles to appear on your payment statement,
                    depending on your financial institution. An email
                    notification will be sent to you once the refund has been
                    processed.
                  </p>
                </div>
              </div>
              <div
                id="exceptions"
                className="mb-6 md:mb-12"
                tabIndex="-1"
                style={{ outline: "none" }}
              >
                <h3 className="u-h3 font-bold">Exceptions</h3>
                <div className="mt-4 md:mt-6 u-p1 post-content">
                  <p>
                    For issues related to the course content, technical
                    difficulties, or any concerns, please contact us using the
                    details below to discuss possible alternatives, including
                    refund or exchange options.
                  </p>
                </div>
              </div>
              <div
                id="additional-terms"
                className="mb-6 md:mb-12"
                tabIndex="-1"
                style={{ outline: "none" }}
              >
                <h3 className="u-h3 font-bold">Additional Terms</h3>
                <div className="mt-4 md:mt-6 u-p1 post-content">
                  <ul>
                    <li>
                      Sale of digital or access-based educational content is
                      final and cannot be returned.
                    </li>
                    <li>
                      Refunds will be issued to the original payment method used
                      during the enrollment process.
                    </li>
                    <li>
                      In the case of technical issues preventing course access,
                      alternative solutions or extensions may be offered.
                    </li>
                    <li>
                      Refunds are not applicable if the course completion
                      certificate has been issued.
                    </li>
                  </ul>
                </div>
              </div>
              <div
                id="questions"
                className="mb-6 md:mb-12"
                style={{ outline: "none" }}
              >
                <h3 className="u-h3 font-bold">Questions</h3>
                <div className="mt-4 md:mt-6 u-p1 post-content">
                  <p>
                    If you have any questions concerning our online course
                    refund policy, feel free to contact our support team at:
                  </p>
                  <p>Phone: +91 7649866386</p>
                  <p>Email: admin@progyaneducations.in</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RefundPolicy;
