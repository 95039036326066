import React from "react";
import { motion } from "framer-motion";

const CareerPage = () => {
  const jobPostings = [
    {
      id: 1,
      title: "Business Development Sales",
      location: "Remote",
      description:
        "Seeking a motivated individual to drive business growth through sales and client acquisition. Previous experience in sales preferred.",
      formUrl: "https://forms.gle/MV4dYDyoT3Prag2h7",
      image: "https://source.unsplash.com/800x600/?business-development",
    },
    {
      id: 2,
      title: "Marketing",
      location: "Remote",
      description:
        "Looking for a creative marketer to develop and execute marketing campaigns across various channels. Strong communication and analytical skills required.",
      formUrl: "https://forms.gle/PsLhRsX1tjbKH4p6A",
      image: "https://source.unsplash.com/800x600/?marketing",
    },
    {
      id: 3,
      title: "Web Development",
      location: "Remote",
      description:
        "Hiring web developers to design, develop, and maintain responsive websites. Proficiency in HTML, CSS, JavaScript, and web frameworks preferred.",
      formUrl: "https://forms.gle/pM9nRdy36CMVPUXX6",
      image: "https://source.unsplash.com/800x600/?web-development",
    },
    {
      id: 4,
      title: "Tutor",
      location: "Remote",
      description:
        "Looking for experienced tutors to provide one-on-one or group tutoring sessions in various subjects. Strong communication and teaching skills required.",
      formUrl: "https://forms.gle/2SHTSqHHiUPFCLTk9",
      image: "https://source.unsplash.com/800x600/?tutor",
    },
    {
      id: 5,
      title: "Teaching Assistant",
      location: "Remote",
      description:
        "Seeking teaching assistants to support instructors in delivering course materials, grading assignments, and assisting students. Previous teaching experience preferred.",
      formUrl: "https://forms.gle/dommknsTA8stwbtc6",
      image: "https://source.unsplash.com/800x600/?teaching",
    },
  ];

  const handleJobPostingClick = (formUrl) => {
    window.open(formUrl, "_blank");
  };

  return (
    <div className="bg-gray-100 p-8 mt-[6rem] w-80%]">
      <h1 className="text-4xl my-12 font-semibold text-center text-gray-900">
        Career Opportunities
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {jobPostings.map((job) => (
          <motion.div
            key={job.id}
            className="bg-white shadow-md rounded-lg overflow-hidden"
            whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
          >
            <img src={job.image} alt={job.title} className="w-full h-64 object-cover" />
            <div className="p-6">
              <h2 className="text-xl font-semibold mb-2 text-gray-800">{job.title}</h2>
              <p className="text-sm text-gray-600">{job.location}</p>
              <p className="text-gray-600 mb-4">{job.description}</p>
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                onClick={() => handleJobPostingClick(job.formUrl)}
              >
                Apply Now
              </button>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default CareerPage;
