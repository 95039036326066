import React, { useState } from "react";
import { FaStar, FaStarHalfAlt, FaThumbsDown } from "react-icons/fa";
import { FaThumbsUp } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";
import CourseReview from "../data/info/reviews.json";

const HighlightedReview = () => {
  const initalFeedback = "Would you recommend this course?";
  const [feedback, setFeedback] = useState(initalFeedback);

  const handleThumbsUpClick = () => {
    setFeedback("Thanks for your recommendation");
  };

  const handleThumbsDownClick = () => {
    setFeedback("Thanks for your feedback");
  };

  const handleDoubleClick = () => {
    setFeedback(initalFeedback);
  };

  const location = useLocation();
  const reviewId = location.pathname.split("/").pop();
  const review = CourseReview.find(
    (review) => review.id === parseInt(reviewId)
  );

  return (
    <div className="w-full h-full mb-5 p-5 border-2 sm:mt-8 rounded-md select-none">
      <div className="flex items-start my-4">
        <h1 className="font-bold lg:leading-4">Highlighted Review</h1>
      </div>
      <div className="flex-col mt-8 text-left ">
        <div className="flex gap-8 mb-4">
          <img
            src={require(`../data/images/Reviews/${review.img}`)}
            alt="image"
            className="w-20 h-20 rounded-full"
          />
          <div>
            <h3 className="font-bold text-gray-300">{review.name}</h3>
            <p className="text-sm font-semibold mt-2 text-gray-500">
              {review.totalReviews}
            </p>
          </div>
        </div>
        <div className="flex gap-7 text-md mb-4">
          <div className="flex">
            <FaStar size={20} fill="yellow" />
            <FaStar size={20} fill="yellow" />
            <FaStar size={20} fill="yellow" />
            <FaStar size={20} fill="yellow" />
            {reviewId % 2 != 0 ? (
              <FaStar size={20} fill="yellow" />
            ) : (
              <FaStarHalfAlt size={20} fill="yellow" />
            )}
          </div>
          <p className="text-sm font-semibold text-gray-500">
            {review.timeFrame}
          </p>
        </div>
        <div className="mb-5">
          <p className="font-semibold">{review.content}</p>
        </div>
        <div className="mt-5">
          <p className="text-sm">{feedback}</p>
          <div className="flex gap-4 mt-6">
            <FaThumbsUp
              onClick={handleThumbsUpClick}
              onDoubleClick={handleDoubleClick}
              size={50}
              className="border hover:bg-zinc-700 p-3 rounded-full "
            />
            <FaThumbsDown
              onClick={handleThumbsDownClick}
              onDoubleClick={handleDoubleClick}
              size={50}
              className="border hover:bg-zinc-700 p-3 rounded-full transform scale-x-[-1]"
            />
            <Link to="/contact" className="hover:underline self-center">
              Report Abuse
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HighlightedReview;
