// Home.js
import React from "react";
import Contact from "./pages/contact";
import Course from "./page/course";
import Testimonials from "./page/testimonial";
import Hero from "./page/Hero";
import Partners from "./pages/partners";
import WhyUs from "./page/whyUs";
import FooterUp from "./components/footerUp";

function Home() {
  return (
    <div>
      <Hero />
      {/* <Scroll /> */}
      <Course />
      <WhyUs />
      {/* <FeeStructure /> */}
      <Testimonials />
      {/* <FAQs /> */}
      <Partners />
      {/* <About /> */}
      <Contact />
      <FooterUp />
    </div>
  );
}

export default Home;
