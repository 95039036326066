import React from "react";
import "../styles/privacyPolicy.css";

function termsandconditions() {
  const handleSelectChange = (e) => {
    const selectedSectionId = e.target.value.substring(1); // Get the section id without '#'
    const selectedSection = document.getElementById(selectedSectionId);
    if (selectedSection) {
      selectedSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  function handleSelectedChange(sectionId) {
    const element = document.querySelector(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
  return (
    <div className="content text-start">
      <div className="py-20 pt-44 md:py-32 md:pt-44">
        <div className="container max-w-6xl">
          <div className="hero text-center mb-12 md:mb-32">
            <h1 className="u-h1 font-bold mb-4">Terms & Conditions</h1>
            <p className="font-bold">Last updated: March 09, 2024</p>
          </div>
          <div className="md:flex justify-between items-start">
            <div className="md:w-4/12 sidebar sticky ">
              <div className="md:hidden mb-12">
                <select onChange={handleSelectChange}>
                  <option value="#agreement-to-terms">
                    agreement to terms and conditions
                  </option>
                  <option value="#cookies">Cookies</option>
                  <option value="#license">License</option>
                  <option value="#hyperLink-of-content">
                    HyperLink to our Content
                  </option>
                  <option value="#content-liability">Content Liability</option>
                  <option value="#reservation-of-rights">
                    Reservation of Rights
                  </option>
                  <option value="#removal-of-links">
                    Removal of links from our website
                  </option>
                  <option value="#disclaimer">Disclaimer</option>
                  <option value="#contact-us">Contact Us</option>
                </select>
              </div>
              <div className="rounded-lg bg-blue-gradient p-8 hidden md:block">
                <h4 className="text-xl text-white font-bold mb-4">Contents</h4>
                <ul className="list-none">
                  <li className="block hover:opacity-75 transition-opacity duration-200 ease-in-out font-sm mb-2">
                    <span className="list-triangle list-triangle--white mr-2"></span>
                    <a
                      onClick={() =>
                        handleSelectedChange("#agreement-to-terms")
                      }
                      className="font-semibold text-white capitalize cursor-pointer"
                    >
                      Agreement to terms and conditions
                    </a>
                  </li>
                  <li className="block hover:opacity-75 transition-opacity duration-200 ease-in-out font-sm mb-2">
                    <span className="list-triangle list-triangle--white mr-2"></span>
                    <a
                      onClick={() => handleSelectedChange("#cookies")}
                      className="font-semibold text-white capitalize cursor-pointer"
                    >
                      Cookies
                    </a>
                  </li>
                  <li className="block hover:opacity-75 transition-opacity duration-200 ease-in-out font-sm mb-2">
                    <span className="list-triangle list-triangle--white mr-2"></span>
                    <a
                      onClick={() => handleSelectedChange("#license")}
                      className="font-semibold text-white capitalize cursor-pointer"
                    >
                      License
                    </a>
                  </li>
                  <li className="block hover:opacity-75 transition-opacity duration-200 ease-in-out font-sm mb-2">
                    <span className="list-triangle list-triangle--white mr-2"></span>
                    <a
                      onClick={() =>
                        handleSelectedChange("#hyperLink-of-content")
                      }
                      className="font-semibold text-white capitalize cursor-pointer"
                    >
                      HyperLink to our Content
                    </a>
                  </li>
                  <li className="block hover:opacity-75 transition-opacity duration-200 ease-in-out font-sm mb-2">
                    <span className="list-triangle list-triangle--white mr-2"></span>
                    <a
                      onClick={() => handleSelectedChange("#content-liability")}
                      className="font-semibold text-white capitalize cursor-pointer"
                    >
                      Content Liability
                    </a>
                  </li>
                  <li className="block hover:opacity-75 transition-opacity duration-200 ease-in-out font-sm mb-2">
                    <span className="list-triangle list-triangle--white mr-2"></span>
                    <a
                      onClick={() =>
                        handleSelectedChange("#reservation-of-rights")
                      }
                      className="font-semibold text-white capitalize cursor-pointer"
                    >
                      Reservation of Rights
                    </a>
                  </li>
                  <li className="block hover:opacity-75 transition-opacity duration-200 ease-in-out font-sm mb-2">
                    <span className="list-triangle list-triangle--white mr-2"></span>
                    <a
                      onClick={() => handleSelectedChange("#removal-of-links")}
                      className="font-semibold text-white capitalize cursor-pointer"
                    >
                      Removal of links from our website
                    </a>
                  </li>
                  <li className="block hover:opacity-75 transition-opacity duration-200 ease-in-out font-sm mb-2">
                    <span className="list-triangle list-triangle--white mr-2"></span>
                    <a
                      onClick={() => handleSelectedChange("#disclaimer")}
                      className="font-semibold text-white capitalize cursor-pointer"
                    >
                      Disclaimer
                    </a>
                  </li>
                  <li className="block hover:opacity-75 transition-opacity duration-200 ease-in-out font-sm">
                    <span className="list-triangle list-triangle--white mr-2"></span>
                    <a
                      onClick={() => handleSelectedChange("#contact-us")}
                      className="font-semibold text-white capitalize cursor-pointer"
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:w-7/12">
              <div
                id="agreement-to-terms"
                className="mb-6 md:mb-12"
                tabIndex="-1"
                style={{ outline: "none" }}
              >
                <h3 className="u-h3 font-bold">
                  AGREEMENT TO TERMS AND CONDITIONS
                </h3>
                <div className="mt-4 md:mt-6 u-p1 post-content">
                  <section className="privacy-policy-title">
                    <p>
                      <strong> Welcome to Progyan Educations!</strong> <br />
                      These terms and conditions outline the rules and
                      regulations for the use of Progyan Educations's Website,
                      located at https://www.progyaneducations.in.
                    </p>
                    <p>
                      By accessing this website, we assume you accept these
                      terms and conditions. Do not continue to use Progyan
                      Educations if you do not agree to take all of the terms
                      and conditions stated on this page.
                    </p>
                  </section>
                </div>
              </div>
              <div
                id="cookies"
                className="mb-6 md:mb-12"
                tabIndex="-1"
                style={{ outline: "none" }}
              >
                <h3 className="u-h3 font-bold">COOKIES</h3>
                <div className="mt-4 md:mt-6 u-p1 post-content">
                  <p>
                    The website uses cookies to help personalize your online
                    experience. By accessing Progyan Educations, you agreed to
                    use the required cookies.
                  </p>
                  <p>
                    A cookie is a text file that is placed on your hard disk by
                    a web page server. Cookies cannot be used to run programs or
                    deliver viruses to your computer. Cookies are uniquely
                    assigned to you and can only be read by a web server in the
                    domain that issued the cookie to you
                  </p>
                  <p>
                    We may use cookies to collect, store, and track information
                    for statistical or marketing purposes to operate our
                    website. You have the ability to accept or decline optional
                    Cookies. There are some required Cookies that are necessary
                    for the operation of our website. These cookies do not
                    require your consent as they always work. Please keep in
                    mind that by accepting required Cookies, you also accept
                    third-party Cookies, which might be used via third-party
                    provided services if you use such services on our website,
                    for example, a video display window provided by third
                    parties and integrated into our website.
                  </p>
                </div>
              </div>
              <div
                id="license"
                className="mb-6 md:mb-12"
                tabIndex="-1"
                style={{ outline: "none" }}
              >
                <h3 className="u-h3 font-bold">LICENSE</h3>
                <div className="mt-4 md:mt-6 u-p1 post-content">
                  <p>
                    Unless otherwise stated, Progyan Educations and/or its
                    licensors own the intellectual property rights for all
                    material on Progyan Educations. All intellectual property
                    rights are reserved. You may access this from Progyan
                    Educations for your own personal use subjected to
                    restrictions set in these terms and conditions.
                  </p>
                  <p>You must not:</p>
                  <ul>
                    <li className="list-disc">
                      <p>Copy or republish material from Progyan Education</p>
                    </li>
                    <li className="list-disc">
                      <p>
                        Sell, rent, or sub-license material from Progyan
                        Educations
                      </p>
                    </li>
                    <li className="list-disc">
                      <p>
                        Reproduce, duplicate or copy material from Progyan
                        Educations
                      </p>
                    </li>
                    <li className="list-disc">
                      <p>Redistribute content from Progyan Educations</p>
                    </li>
                    <h4 className="font-bold mb-2">
                      This Agreement shall begin on the date hereof.
                    </h4>
                    <p>
                      Parts of this website offer users an opportunity to post
                      and exchange opinions and information in certain areas of
                      the website. Progyan Educations does not filter, edit,
                      publish or review Comments before their presence on the
                      website. Comments do not reflect the views and opinions of
                      Progyan Educations, its agents, and/or affiliates.
                      Comments reflect the views and opinions of the person who
                      posts their views and opinions. To the extent permitted by
                      applicable laws, Progyan Educations shall not be liable
                      for the Comments or any liability, damages, or expenses
                      caused and/or suffered as a result of any use of and/or
                      posting of and/or appearance of the Comments on this
                      website.{" "}
                    </p>
                    <p>
                      Progyan Educations reserves the right to monitor all
                      Comments and remove any Comments that can be considered
                      inappropriate, offensive, or causes breach of these Terms
                      and Conditions.{" "}
                    </p>
                  </ul>
                  <p>You warrant and represent that:</p>
                  <ul>
                    <li className="list-disc">
                      <p>
                        You are entitled to post the Comments on our website and
                        have all necessary licenses and consents to do so;
                      </p>
                    </li>
                    <li className="list-disc">
                      <p>
                        The Comments do not invade any intellectual property
                        right, including without limitation copyright, patent,
                        or trademark of any third party;
                      </p>
                    </li>
                    <li className="list-disc">
                      <p>
                        The Comments do not contain any defamatory, libelous,
                        offensive, indecent, or otherwise unlawful material,
                        which is an invasion of privacy.
                      </p>
                    </li>
                    <li className="list-disc">
                      <p>
                        The Comments will not be used to solicit or promote
                        business or custom or present commercial activities or
                        unlawful activity.
                      </p>
                    </li>
                  </ul>
                  <p>
                    You hereby grant Progyan Educations a non-exclusive license
                    to use, reproduce, edit and authorize others to use,
                    reproduce and edit any of your Comments in any and all
                    forms, formats, or media.
                  </p>
                </div>
              </div>
              <div
                id="hyperLink-of-content"
                className="mb-6 md:mb-12"
                tabIndex="-1"
                style={{ outline: "none" }}
              >
                <h3 className="u-h3 font-bold">HYPERLINKING TO OUR CONTENT</h3>
                <div className="mt-4 md:mt-6 u-p1 post-content">
                  <p>
                    The following organizations may link to our Website without
                    prior written approval:
                  </p>
                  <ul>
                    <li className="list-disc">Government agencies;</li>
                    <li className="list-disc"> Search engines;</li>
                    <li className="list-disc"> News organizations;</li>
                    <li className="list-disc">
                      Online directory distributors may link to our Website in
                      the same manner as they hyperlink to the Websites of other
                      listed businesses; and
                    </li>
                    <li className="list-disc">
                      System-wide Accredited Businesses except soliciting
                      non-profit organizations, charity shopping malls, and
                      charity fundraising groups which may not hyperlink to our
                      Web site.
                    </li>
                  </ul>
                  <p className="mt-4">
                    These organizations may link to our home page, to
                    publications, or to other Website information so long as the
                    link: <b>(a)</b> is not in any way deceptive; <b>(b)</b>{" "}
                    does not falsely imply sponsorship, endorsement, or approval
                    of the linking party and its products and/or services; and
                    <b>(c)</b> fits within the context of the linking party's
                    site.
                  </p>
                  <p>
                    We may consider and approve other link requests from the
                    following types of organizations:
                  </p>
                  <ul>
                    <li className="list-disc">
                      commonly-known consumer and/or business information
                      sources;
                    </li>
                    <li className="list-disc"> dot.com community sites;</li>
                    <li className="list-disc">
                      {" "}
                      associations or other groups representing charities;
                    </li>
                    <li className="list-disc">
                      online directory distributors;
                    </li>
                    <li className="list-disc"> internet portals;</li>
                    <li className="list-disc">
                      accounting, law, and consulting firms; and
                    </li>
                    <li className="list-disc">
                      educational institutions and trade associations.
                    </li>
                  </ul>
                  <p className="mt-4">
                    We will approve link requests from these organizations if we
                    decide that: <b>(a)</b> the link would not make us look
                    unfavorably to ourselves or to our accredited businesses;{" "}
                    <b>(b)</b> the organization does not have any negative
                    records with us; <b>(c)</b> the benefit to us from the
                    visibility of the hyperlink compensates the absence of
                    Progyan Educations; and <b>(d)</b> the link is in the
                    context of general resource information.
                  </p>
                  <p>
                    These organizations may link to our home page so long as the
                    link: <b>(a)</b> is not in any way deceptive; <b>(b)</b>{" "}
                    does not falsely imply sponsorship, endorsement, or approval
                    of the linking party and its products or services; and{" "}
                    <b>(c)</b> fits within the context of the linking party's
                    site.
                  </p>
                  <p>
                    If you are one of the organizations listed in paragraph 2
                    above and are interested in linking to our website, you must
                    inform us by sending an e-mail to Progyan Educations. Please
                    include your name, your organization name, contact
                    information as well as the URL of your site, a list of any
                    URLs from which you intend to link to our Website, and a
                    list of the URLs on our site to which you would like to
                    link. Wait 2-3 weeks for a response.
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>
                          Approved organizations may hyperlink to our Website as
                          follows:
                        </strong>
                      </p>
                    </li>
                    <li className="list-disc">
                      {" "}
                      By use of our corporate name; or
                    </li>
                    <li className="list-disc">
                      Using any other description of our Website being linked to
                      that makes sense within the context and format of content
                      on the linking party's site
                    </li>
                    <li className="list-disc">
                      By use of the uniform resource locator being linked to; or
                    </li>
                  </ul>
                  <p className="mt-4">
                    No use of Progyan Educations's logo or other artwork will be
                    allowed for linking absent a trademark license agreement.{" "}
                  </p>
                </div>
              </div>
              <div
                id="content-liability"
                className="mb-6 md:mb-12"
                tabIndex="-1"
                style={{ outline: "none" }}
              >
                <h3 className="u-h3 font-bold">CONTENT LIABILITY</h3>
                <div className="mt-4 md:mt-6 u-p1 post-content">
                  <p>
                    We shall not be held responsible for any content that
                    appears on your Website. You agree to protect and defend us
                    against all claims that are raised on your Website. No
                    link(s) should appear on any Website that may be interpreted
                    as libelous, obscene, or criminal, or which infringes,
                    otherwise violates, or advocates the infringement or other
                    violation of, any third party rights.
                  </p>
                </div>
              </div>
              <div id="reservation-of-rights" className=" mb-6 md:mb-12 ">
                <h3 className="u-h3 font-bold">RESERVATION OF RIGHTS</h3>
                <div className="mt-4 md:mt-6 u-p1 post-content">
                  <p>
                    We reserve the right to request that you remove all links or
                    any particular link to our Website. You approve to
                    immediately remove all links to our Website upon request. We
                    also reserve the right to amend these terms and conditions
                    and its linking policy at any time. By continuously linking
                    to our Website, you agree to be bound to and follow these
                    linking terms and conditions.
                  </p>
                </div>
              </div>
              <div id="removal-of-links" className=" mb-6 md:mb-12 ">
                <h3 className="u-h3 font-bold">
                  REMOVAL OF LINKS FROM OUR WEBSITE
                </h3>
                <div className="mt-4 md:mt-6 u-p1 post-content">
                  <p>
                    If you find any link on our Website that is offensive for
                    any reason, you are free to contact and inform us at any
                    moment. We will consider requests to remove links, but we
                    are not obligated to or so or to respond to you directly.
                  </p>
                  <p>
                    We do not ensure that the information on this website is
                    correct. We do not warrant its completeness or accuracy, nor
                    do we promise to ensure that the website remains available
                    or that the material on the website is kept up to date.
                  </p>
                  <section className="privacy-policy-title"></section>
                </div>
              </div>
              <div id="disclaimer" className=" mb-6 md:mb-12 ">
                <h3 className="u-h3 font-bold">DISCLAIMER</h3>
                <div className="mt-4 md:mt-6 u-p1 post-content">
                  <p>
                    To the maximum extent permitted by applicable law, we
                    exclude all representations, warranties, and conditions
                    relating to our website and the use of this website.
                  </p>
                  <p>
                    <strong>Nothing in this disclaimer will:</strong>
                    <br />
                  </p>
                  <ul>
                    <li className="list-disc">
                      limit or exclude our or your liability for death or
                      personal injury;
                    </li>
                    <li className="list-disc">
                      limit or exclude our or your liability for fraud or
                      fraudulent misrepresentation;
                    </li>
                    <li className="list-disc">
                      limit any of our or your liabilities in any way that is
                      not permitted under applicable law; or
                    </li>
                    <li className="list-disc">
                      exclude any of our or your liabilities that may not be
                      excluded under applicable law
                    </li>
                  </ul>
                  <p className="mt-3">
                    The limitations and prohibitions of liability set in this
                    Section and elsewhere in this disclaimer: <b>(a)</b> are
                    subject to the preceding paragraph; and <b>(b)</b> govern
                    all liabilities arising under the disclaimer, including
                    liabilities arising in contract, in tort, and for breach of
                    statutory duty.
                  </p>
                  <p>
                    As long as the website and the information and services on
                    the website are provided free of charge, we will not be
                    liable for any loss or damage of any nature.
                  </p>
                  <section className="privacy-policy-title"></section>
                </div>
              </div>
              <div id="contact-us">
                <h3 className="u-h3 font-bold">CONTACT US</h3>
                <div className="mt-4 md:mt-6 u-p1 post-content">
                  <p>
                    If you want to resolve any complaint or want to recieve
                    furthur information regarding the use of site. You can
                    contact us at:
                  </p>
                  <p>28/5, Progyan Educations,Rama Mandi ,</p>
                  <p> Jalandhar Punjab (144023), India</p>
                  <p>
                    By visiting this page on our website:
                    <a
                      href="https://www.progyaneducations.in/contact"
                      rel="external nofollow noopener"
                      target="_blank"
                    >
                      https://www.progyaneducations.in/contact
                    </a>
                  </p>
                  <p>By phone number: +91 7649866386</p>
                  <p>By email:info@progyaneducations.in</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default termsandconditions;
